//
// All icons are imported from https://fontawesome.com/icons?d=gallery
// Icons are under the license https://fontawesome.com/license
//

// Note: when adding new icons, review https://developer.mozilla.org/en-US/docs/Mozilla/Developer_guide/RTL_Guidelines
// to determine whether or not the icons should be mirrored in right-to-left languages.

import React from "react";

import oc from "open-color";
import clsx from "clsx";
import { Theme } from "../element/types";
import { THEME } from "../constants";
import { ReactComponent as LibrarySvg } from "../assets/icons/library.svg";
import { ReactComponent as ImageSearchSvg } from "../assets/icons/bt_image-search.svg";
import { ReactComponent as ImageSvg } from "../assets/icons/image.svg";
import { ReactComponent as PagesSvg } from "../assets/icons/pages.svg";
import { ReactComponent as SaveSvg } from "../assets/icons/save.svg";
import { ReactComponent as SaveToSvg } from "../assets/icons/save-to.svg";
import { ReactComponent as TrashSvg } from "../assets/icons/remove.svg";
import { ReactComponent as ResetSvg } from "../assets/icons/reset.svg";
import { ReactComponent as DuplicateSvg } from "../assets/icons/duplicate.svg";
import { ReactComponent as DriveSvg } from "../assets/icons/drive.svg";
import { ReactComponent as AlignTopSvg } from "../assets/icons/align-top.svg";
import { ReactComponent as AlignBottomSvg } from "../assets/icons/align-bottom.svg";
import { ReactComponent as AlignLeftSvg } from "../assets/icons/align-left.svg";
import { ReactComponent as AlignRightSvg } from "../assets/icons/align-right.svg";
import { ReactComponent as CenterVerticallySvg } from "../assets/icons/align-vertical-center.svg";
import { ReactComponent as CenterHorizontallySvg } from "../assets/icons/align-horizontal-center.svg";
import { ReactComponent as CollaborateSvg } from "../assets/icons/collaborate.svg";
import { ReactComponent as StrokeWidthBaseSvg } from "../assets/icons/stroke-solid.svg";
import { ReactComponent as StrokeStyleDashedSvg } from "../assets/icons/stroke-dash.svg";
import { ReactComponent as StrokeStyleDottedSvg } from "../assets/icons/stroke-dotted.svg";
import { ReactComponent as StrokeSvg } from "../assets/icons/stroke.svg";
import { ReactComponent as ArrowheadNoneSvg } from "../assets/icons/left-none.svg";
import { ReactComponent as ArrowheadArrowLeftSvg } from "../assets/icons/left-arrow.svg";
import { ReactComponent as ArrowheadArrowRightSvg } from "../assets/icons/right-arrow.svg";
import { ReactComponent as ArrowheadDotLeftSvg } from "../assets/icons/left-dot.svg";
import { ReactComponent as ArrowheadDotRightSvg } from "../assets/icons/right-dot.svg";
import { ReactComponent as ViewBackgroundSvg } from "../assets/icons/view-background.svg";
import { ReactComponent as ArrowheadSvg } from "../assets/icons/arrow-style.svg";
import { ReactComponent as FontSizeSvg } from "../assets/icons/font-size.svg";
import { ReactComponent as FillSvg } from "../assets/icons/fill.svg";
import { ReactComponent as OpacitySvg } from "../assets/icons/opacity.svg";
import { ReactComponent as MutiObjectsSvg } from "../assets/icons/multi-select.svg";
import { ReactComponent as UngroupStaticSvg } from "../assets/icons/ungroup.svg";
import { ReactComponent as GroupSettingSvg } from "../assets/icons/group.svg";
import { ReactComponent as StrokeColorSvg } from "../assets/icons/color.svg";
import { ReactComponent as ProfileSvg } from "../assets/icons/user-profile.svg";
import { ReactComponent as SignOutSvg } from "../assets/icons/sign-out.svg";
import { ReactComponent as FlashCollabSvg } from "../assets/icons/flashcollab-on.svg";
import { ReactComponent as FlashCollabShutSvg } from "../assets/icons/flashcollab-muted.svg";
import { ReactComponent as CloseWinSvg } from "../assets/icons/close_new.svg";
import { ReactComponent as CloseSvg } from "../assets/icons/close.svg";
import { ReactComponent as CloseWhiteSvg } from "../assets/icons/close-white.svg";
import { ReactComponent as LibMenuSvg } from "../assets/icons/lib-menu.svg";
import { ReactComponent as RecentlyEditedSvg } from "../assets/icons/recently_edited.svg";
import { ReactComponent as ScannerCodeSvg } from "../assets/icons/scanner-code.svg";
import { ReactComponent as GoogleSvg } from "../assets/icons/google.svg";
import { ReactComponent as MicrosoftSvg } from "../assets/icons/microsoft.svg";
import { ReactComponent as FreeSvg } from "../assets/icons/free.svg";
import { ReactComponent as StandardSvg } from "../assets/icons/standard.svg";
import { ReactComponent as ProSvg } from "../assets/icons/pro.svg";
import { ReactComponent as TickSvg } from "../assets/icons/tick.svg";
import { ReactComponent as SearchSvg } from "../assets/icons/search.svg";
import { ReactComponent as SearchDashboardSvg } from "../assets/icons/search-dashboard.svg";
import { ReactComponent as DownloadSvg } from "../assets/icons/download.svg";
import { ReactComponent as ClbFileSvg } from "../assets/icons/clb-file.svg";
import { ReactComponent as PngFileSvg } from "../assets/icons/png-file.svg";
import { ReactComponent as PdfFileSvg } from "../assets/icons/pdf-file.svg";
import { ReactComponent as SvgFileSvg } from "../assets/icons/svg-file.svg";
import { ReactComponent as MinimizeSvg } from "../assets/icons/minimize.svg";
import { ReactComponent as MinimizeWhiteSvg } from "../assets/icons/minimize-white.svg";
import { ReactComponent as GoogleMeetSvg } from "../assets/icons/google-meet.svg";
import { ReactComponent as GoogleClassroomSvg } from "../assets/icons/google-classroom.svg";
import { ReactComponent as GoogledDriveSvg } from "../assets/icons/google-drive.svg";
import { ReactComponent as AiSearchSvg } from "../assets/icons/bt_search_ai.svg";
import { ReactComponent as ImageAiSvg } from "../assets/icons/image-AI.svg";
import { ReactComponent as GoogledSearchSvg } from "../assets/icons/bt_search_google.svg";
import { ReactComponent as SwitchLeftSvg } from "../assets/icons/switch-left.svg";
import { ReactComponent as SwitchRightSvg } from "../assets/icons/switch-right.svg";
import { ReactComponent as ShareScreenSvg } from "../assets/icons/share-screen.svg";
import { ReactComponent as CloudServiceSvg } from "../assets/icons/cloud-service.svg";
import { ReactComponent as OpenSvg } from "../assets/icons/open.svg";
import { ReactComponent as ResizeMiniSvg } from "../assets/icons/resize-mini.svg";
import { ReactComponent as ResizeMaxSvg } from "../assets/icons/resize-max.svg";
import { ReactComponent as ResizeNormalSvg } from "../assets/icons/resize-normal.svg";
import { ReactComponent as RefreshSvg } from "../assets/icons/refresh.svg";
import { ReactComponent as CollabLogoSvg } from "../assets/icons/logo.svg";
import { ReactComponent as UpgradeSvg } from "../assets/icons/upgrade.svg";
import { ReactComponent as CameraMutedSvg } from "../assets/icons/camera-muted.svg";
import { ReactComponent as CameraOnSvg } from "../assets/icons/camera-on.svg";
import { ReactComponent as FootballSvg } from "../assets/icons/football.svg";
import { ReactComponent as SelectionSvg } from "../assets/icons/select.svg";
import { ReactComponent as BackgroundSvg } from "../assets/icons/background.svg";
import { ReactComponent as DarkModeSvg } from "../assets/icons/dark_mode.svg";
import { ReactComponent as BasketballSvg } from "../assets/icons/basketball.svg";
import { ReactComponent as MarkerSvg } from "../assets/icons/marker.svg";
import { ReactComponent as BrushSvg } from "../assets/icons/brush.svg";
import { ReactComponent as PenSvg } from "../assets/icons/pen.svg";
import { ReactComponent as MagicPenSvg } from "../assets/icons/magic_pen.svg";
import { ReactComponent as PencilSvg } from "../assets/icons/pencil.svg";
import { ReactComponent as TranslateSvg } from "../assets/icons/translate.svg";
import { ReactComponent as RequestAISvg } from "../assets/icons/text-request-ai.svg";
import { ReactComponent as RequestAIColorSvg } from "../assets/icons/text-request-ai-color.svg";
import { ReactComponent as TranslateNoColourSvg } from "../assets/icons/translate_no_colour.svg";
import { ReactComponent as SendSvg } from "../assets/icons/send.svg";
import { ReactComponent as BlueSendSvg } from "../assets/icons/blue-send.svg";
import { ReactComponent as AiUploadImgSvg } from "../assets/icons/ai_chat_upload_img.svg";
import { ReactComponent as DiagramSvg } from "../assets/icons/diagram.svg";
import { ReactComponent as LineSvg } from "../assets/icons/line.svg";
import { ReactComponent as RectangleSvg } from "../assets/icons/rectangle.svg";
import { ReactComponent as ArrowSvg } from "../assets/icons/arrow.svg";
import { ReactComponent as DiamondSvg } from "../assets/icons/diamond.svg";
import { ReactComponent as TextSvg } from "../assets/icons/text.svg";
import { ReactComponent as MagicTextSvg } from "../assets/icons/magic_text.svg";
import { ReactComponent as ColorPicker } from "../assets/icons/color-picker.svg";
import { ReactComponent as EraserSvg } from "../assets/icons/eraser.svg";
import { ReactComponent as EraserBigSvg } from "../assets/icons/eraser-big.svg";
import { ReactComponent as EraserObjectSvg } from "../assets/icons/eraser-object.svg";
import { ReactComponent as ZoomInSvg } from "../assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOutSvg } from "../assets/icons/zoom-out.svg";
import { ReactComponent as RedoSvg } from "../assets/icons/redo.svg";
import { ReactComponent as UndoSvg } from "../assets/icons/undo.svg";
import { ReactComponent as GustureSvg } from "../assets/icons/gusture.svg";
import { ReactComponent as EllipseSvg } from "../assets/icons/ellipse.svg";
import { ReactComponent as Off50Svg } from "../assets/icons/off-50.svg";
import { ReactComponent as BestDealSvg } from "../assets/icons/best-deal.svg";
import { ReactComponent as YoutubeSvg } from "../assets/icons/youtube.svg";
import { ReactComponent as StemSvg } from "../assets/icons/stem-logo.svg";
import { ReactComponent as SchoolSvg } from "../assets/icons/school-logo.svg";
import { ReactComponent as TwitterSvg } from "../assets/icons/twitter.svg";
import { ReactComponent as LinkedinSvg } from "../assets/icons/linkedin.svg";
import { ReactComponent as InstagramSvg } from "../assets/icons/instagram.svg";
import { ReactComponent as FacebookSvg } from "../assets/icons/facebook.svg";
import { ReactComponent as CopySvg } from "../assets/icons/copy.svg";
import { ReactComponent as UnionSvg } from "../assets/icons/union.svg";
import { ReactComponent as EmailSvg } from "../assets/icons/email.svg";
import { ReactComponent as TemplateSvg } from "../assets/icons/template.svg";
import { ReactComponent as GoogleDocsSvg } from "../assets/icons/docs.svg";
import { ReactComponent as GoogleSlidesSvg } from "../assets/icons/slides.svg";
import { ReactComponent as GoogleSheetSvg } from "../assets/icons/sheets.svg";
import { ReactComponent as ChatSvg } from "../assets/icons/chat.svg";
import { ReactComponent as AiSvg } from "../assets/icons/ai.svg";
import { ReactComponent as CrossSvg } from "../assets/icons/cross.svg";
import { ReactComponent as EyeSvg } from "../assets/icons/eye.svg";
import { ReactComponent as BlindSvg } from "../assets/icons/blind.svg";
import { ReactComponent as MermaidSvg } from "../assets/icons/mermaid-logo.svg";
import { ReactComponent as FlowChartSvg } from "../assets/icons/flow-chart.svg";
import { ReactComponent as DrawioSvg } from "../assets/icons/drawio.svg";
import { ReactComponent as NoteSvg } from "../assets/icons/note.svg";
import { ReactComponent as PersonalBoardSvg } from "../assets/icons/personal-board.svg";
import { ReactComponent as AIBotSvg } from "../assets/icons/ai_bot.svg";
import { ReactComponent as AIBotCloseSvg } from "../assets/icons/ai_bot_close.svg";

const iconFillColor = (theme: Theme) => "var(--icon-fill-color)";

const handlerColor = (theme: Theme) =>
  theme === THEME.LIGHT ? oc.white : "#1e1e1e";

type Opts = {
  width?: number;
  height?: number;
  mirror?: true;
} & React.SVGProps<SVGSVGElement>;

export const createIcon = (
  d: string | React.ReactNode,
  opts: number | Opts = 512,
) => {
  const {
    width = 512,
    height = width,
    mirror,
    style,
    ...rest
  } = typeof opts === "number" ? ({ width: opts } as Opts) : opts;
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox={`0 0 ${width} ${height}`}
      className={clsx({ "rtl-mirror": mirror })}
      style={style}
      {...rest}
    >
      {typeof d === "string" ? <path fill="currentColor" d={d} /> : d}
    </svg>
  );
};

export const createIconFull = (
  d: string | React.ReactNode,
  opts: number | Opts = 512,
) => {
  const {
    width = 512,
    height = width,
    mirror,
    style,
    ...rest
  } = typeof opts === "number" ? ({ width: opts } as Opts) : opts;
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox={`0 0 ${width} ${height}`}
      className={clsx({ "rtl-mirror": mirror })}
      style={style}
      {...rest}
    >
      {typeof d === "string" ? <path fill="currentColor" d={d} /> : d}
    </svg>
  );
};
export const createStringIcon = (icon: string) => {
  return <span dangerouslySetInnerHTML={{ __html: icon }}></span>;
};
const tablerIconCustomProps: Opts = {
  width: 1024,
  height: 1024,
  fill: "none",
  strokeWidth: 2,
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
} as const;

const tablerIconProps: Opts = {
  width: 24,
  height: 24,
  fill: "none",
  strokeWidth: 2,
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
} as const;

const modifiedTablerIconProps: Opts = {
  width: 20,
  height: 20,
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
} as const;

// -----------------------------------------------------------------------------

// tabler-icons: present
export const PlusPromoIcon = createIcon(
  <g strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x={3} y={8} width={18} height={4} rx={1} />
    <line x1={12} y1={8} x2={12} y2={21} />
    <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
    <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
  </g>,
  tablerIconProps,
);

// tabler-icons: book
export const LibraryIcon = <LibrarySvg />;

// tabler-icons: plus
export const PlusIcon = createIcon(
  <svg strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>,
  tablerIconProps,
);

// tabler-icons: dots-vertical
export const DotsIcon = createIcon(
  <g strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="12" cy="19" r="1"></circle>
    <circle cx="12" cy="5" r="1"></circle>
  </g>,
  tablerIconProps,
);

// tabler-icons: pinned
export const PinIcon = createIcon(
  <svg strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 4v6l-2 4v2h10v-2l-2 -4v-6"></path>
    <line x1="12" y1="16" x2="12" y2="21"></line>
    <line x1="8" y1="4" x2="16" y2="4"></line>
  </svg>,
  tablerIconProps,
);

// tabler-icons: lock-open (via Figma)
export const UnlockedIcon = createIcon(
  <g>
    <path
      d="M13.542 8.542H6.458a2.5 2.5 0 0 0-2.5 2.5v3.75a2.5 2.5 0 0 0 2.5 2.5h7.084a2.5 2.5 0 0 0 2.5-2.5v-3.75a2.5 2.5 0 0 0-2.5-2.5Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M10 13.958a1.042 1.042 0 1 0 0-2.083 1.042 1.042 0 0 0 0 2.083Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <mask
      id="UnlockedIcon"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x={6}
      y={1}
      width={9}
      height={9}
    >
      <path
        stroke="none"
        d="M6.399 9.561V5.175c0-.93.401-1.823 1.116-2.48a3.981 3.981 0 0 1 2.693-1.028c1.01 0 1.98.37 2.694 1.027.715.658 1.116 1.55 1.116 2.481"
        fill="#fff"
      />
    </mask>
    <g mask="url(#UnlockedIcon)">
      <path
        stroke="none"
        d="M5.149 9.561v1.25h2.5v-1.25h-2.5Zm5.06-7.894V.417v1.25Zm2.559 3.508v1.25h2.5v-1.25h-2.5ZM7.648 8.51V5.175h-2.5V8.51h2.5Zm0-3.334c0-.564.243-1.128.713-1.561L6.668 1.775c-.959.883-1.52 2.104-1.52 3.4h2.5Zm.713-1.561a2.732 2.732 0 0 1 1.847-.697v-2.5c-1.31 0-2.585.478-3.54 1.358L8.36 3.614Zm1.847-.697c.71 0 1.374.26 1.847.697l1.694-1.839a5.231 5.231 0 0 0-3.54-1.358v2.5Zm1.847.697c.47.433.713.997.713 1.561h2.5c0-1.296-.56-2.517-1.52-3.4l-1.693 1.839Z"
        fill="currentColor"
      />
    </g>
  </g>,
  modifiedTablerIconProps,
);

// tabler-icons: lock (via Figma)
export const LockedIcon = createIcon(
  <g strokeWidth="1.25">
    <path d="M13.542 8.542H6.458a2.5 2.5 0 0 0-2.5 2.5v3.75a2.5 2.5 0 0 0 2.5 2.5h7.084a2.5 2.5 0 0 0 2.5-2.5v-3.75a2.5 2.5 0 0 0-2.5-2.5Z" />
    <path d="M10 13.958a1.042 1.042 0 1 0 0-2.083 1.042 1.042 0 0 0 0 2.083Z" />
    <path d="M6.667 8.333V5.417C6.667 3.806 8.159 2.5 10 2.5c1.841 0 3.333 1.306 3.333 2.917v2.916" />
  </g>,
  modifiedTablerIconProps,
);

// custom
export const WelcomeScreenMenuArrow = createIcon(
  <>
    <path
      d="M38.5 83.5c-14-2-17.833-10.473-21-22.5C14.333 48.984 12 22 12 12.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.005 10.478 7.905 14.423L6 25.75l6.005-15.273Z"
      fill="currentColor"
    />
    <path
      d="M12.005 10.478c1.92 3.495 3.838 7 7.905 14.423m-7.905-14.423c3.11 5.683 6.23 11.368 7.905 14.423m0 0c-3.68.226-7.35.455-13.91.85m13.91-.85c-5.279.33-10.566.647-13.91.85m0 0c1.936-4.931 3.882-9.86 6.005-15.273M6 25.75c2.069-5.257 4.135-10.505 6.005-15.272"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </>,
  { width: 41, height: 94, fill: "none" },
);

// custom
export const WelcomeScreenHelpArrow = createIcon(
  <>
    <path
      d="M18.026 1.232c-5.268 13.125-5.548 33.555 3.285 42.311 8.823 8.75 33.31 12.304 42.422 13.523"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m72.181 59.247-13.058-10-2.948 13.62 16.006-3.62Z"
      fill="currentColor"
    />
    <path
      d="M72.181 59.247c-3.163-2.429-6.337-4.856-13.058-10m13.058 10c-5.145-3.936-10.292-7.882-13.058-10m0 0c-.78 3.603-1.563 7.196-2.948 13.62m2.948-13.62c-1.126 5.168-2.24 10.346-2.948 13.62m0 0c5.168-1.166 10.334-2.343 16.006-3.62m-16.006 3.62c5.51-1.248 11.01-2.495 16.006-3.62"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </>,
  { width: 85, height: 71, fill: "none" },
);

// custom
export const WelcomeScreenTopToolbarArrow = createIcon(
  <>
    <path
      d="M1 77c14-2 31.833-11.973 35-24 3.167-12.016-6-35-9.5-43.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m24.165 1.093-2.132 16.309 13.27-4.258-11.138-12.05Z"
      fill="currentColor"
    />
    <path
      d="M24.165 1.093c-.522 3.953-1.037 7.916-2.132 16.309m2.131-16.309c-.835 6.424-1.68 12.854-2.13 16.308m0 0c3.51-1.125 7.013-2.243 13.27-4.257m-13.27 4.257c5.038-1.608 10.08-3.232 13.27-4.257m0 0c-3.595-3.892-7.197-7.777-11.14-12.05m11.14 12.05c-3.837-4.148-7.667-8.287-11.14-12.05"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </>,
  { width: 38, height: 78, fill: "none" },
);

// custom
export const ExcalLogo = createIcon(
  <>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.271"
        y1="-0.664"
        x2="0.765"
        y2="0.77"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#7dc9c1" />
        <stop offset="0.323" stop-color="#00a3ca" />
        <stop offset="0.699" stop-color="#005fac" />
        <stop offset="1" stop-color="#00368c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.042"
        y1="1.324"
        x2="1.244"
        y2="-0.105"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#e50026" />
        <stop offset="0.493" stop-color="#960c19" />
        <stop offset="1" stop-color="#3b0c15" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.005"
        y1="0.892"
        x2="1.975"
        y2="-0.863"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0.213" stop-color="#ea3516" />
        <stop offset="0.678" stop-color="#d52719" />
        <stop offset="1" stop-color="#cd221b" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="1.035"
        y1="0.361"
        x2="0.021"
        y2="0.561"
      >
        <stop offset="0" stop-color="#7dc9c1" />
        <stop offset="0.323" stop-color="#00a3ca" />
        <stop offset="0.699" stop-color="#005fac" />
        <stop offset="1" stop-color="#00368c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1="0.707"
        y1="1.08"
        x2="0.078"
        y2="-0.15"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#b0569c" />
        <stop offset="0.493" stop-color="#6f2e87" />
        <stop offset="1" stop-color="#3e2682" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-6"
        x1="-0.776"
        y1="1.613"
        x2="1.012"
        y2="0.165"
      >
        <stop offset="0" stop-color="#e50026" />
        <stop offset="0.493" stop-color="#960c19" />
        <stop offset="1" stop-color="#3b0c15" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-7"
        x1="1.162"
        y1="0.087"
        x2="0.28"
        y2="0.649"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#ea3516" />
        <stop offset="1" stop-color="#cd221b" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-8"
        x1="0.642"
        y1="0.947"
        x2="-0.286"
        y2="-0.489"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#d49cc6" />
        <stop offset="0.493" stop-color="#6f2e87" />
        <stop offset="1" stop-color="#3e2682" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-9"
        x1="1.648"
        y1="0.341"
        x2="0.176"
        y2="0.542"
      >
        <stop offset="0" stop-color="#7dc9c1" />
        <stop offset="0.323" stop-color="#00a3ca" />
        <stop offset="0.699" stop-color="#005fac" />
        <stop offset="1" stop-color="#00368c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-10"
        x1="-0.107"
        y1="-1.073"
        x2="0.948"
        y2="1.344"
      >
        <stop offset="0" stop-color="#7dc9c1" />
        <stop offset="0.323" stop-color="#00a3ca" />
        <stop offset="0.699" stop-color="#005fac" />
        <stop offset="1" stop-color="#00368c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-11"
        x1="0.796"
        y1="1.544"
        x2="-0.282"
        y2="-2.263"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#d092c0" />
        <stop offset="0.493" stop-color="#6f2e87" />
        <stop offset="1" stop-color="#3e2682" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-12"
        x1="1.551"
        y1="0.368"
        x2="-3.136"
        y2="0.811"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#7dc9c1" />
        <stop offset="0.174" stop-color="#00a3ca" />
        <stop offset="0.699" stop-color="#005fac" />
        <stop offset="1" stop-color="#00368c" />
      </linearGradient>
    </defs>
    <g id="g_76" data-name="g 76" transform="translate(-44.04 -17.128)">
      <g id="g_18" data-name="g 18" transform="translate(0 -431.552)">
        <g id="g_17" data-name="g 17">
          <g id="g_16" data-name="g 16">
            <path
              id="path_47"
              data-name="path 47"
              d="M185.2,628.706,207.306,650.6c18.668,6.2,26.94-4.6,29.722-21.894Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient)"
            />
            <path
              id="path_48"
              data-name="path 48"
              d="M185.2,548.5h51.828c-1.1-15.91-9-25.47-27.965-23.28Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-2)"
            />
            <path
              id="path_49"
              data-name="path 49"
              d="M132.931,515.441c25.621-5.546,53.659-2.043,76.132,9.781a83.168,83.168,0,0,1,27.965,23.28L283.8,501.868a131.828,131.828,0,0,0-99.556-45.029c-72.984,0-132.134,58.967-132.134,131.728a109.2,109.2,0,0,1,80.818-73.126Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="path_50"
              data-name="path 50"
              d="M207.306,650.6a66.883,66.883,0,0,1-23.059,4.088A66.149,66.149,0,0,1,118,588.567C105.406,648.7,165.286,722.2,233.44,710.956A132.83,132.83,0,0,0,283.8,675.342l-46.775-46.636h0A66.88,66.88,0,0,1,207.306,650.6Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-4)"
            />
            <path
              id="path_51"
              data-name="path 51"
              d="M233.44,710.956C169.167,717.377,118,654.031,118,588.567a66.053,66.053,0,0,1,40.922-61.084c-53.364-2.189-97.069,42.11-101.9,96.845A132.485,132.485,0,0,0,233.44,710.956Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-5)"
            />
            <path
              id="path_52"
              data-name="path 52"
              d="M209.063,525.222l-6.881,6.641A78.054,78.054,0,0,1,237.028,548.5a83.168,83.168,0,0,0-27.965-23.28Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-6)"
            />
            <path
              id="path_53"
              data-name="path 53"
              d="M154.747,481.871A121.882,121.882,0,0,1,260.38,525.148l23.423-23.28a132.469,132.469,0,0,0-224.3,42.984,121.922,121.922,0,0,1,95.241-62.981Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-7)"
            />
            <path
              id="path_54"
              data-name="path 54"
              d="M233.44,710.956C169.167,717.377,118,654.031,118,588.567a65.739,65.739,0,0,1,14.642-41.452c-20.423,25.251-22.693,55.246-13.91,84.51,13.618,45.467,63.4,88.744,114.712,79.331Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-8)"
            />
            <path
              id="path_55"
              data-name="path 55"
              d="M118,588.567c0,65.464,51.171,128.81,115.444,122.389A67.008,67.008,0,0,1,163.459,651.4,66.234,66.234,0,0,1,118,588.567Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-9)"
            />
            <path
              id="path_56"
              data-name="path 56"
              d="M199.18,642.574l8.126,8.026a66.88,66.88,0,0,0,29.722-21.894,93.073,93.073,0,0,1-37.848,13.868Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-10)"
            />
            <path
              id="path_57"
              data-name="path 57"
              d="M121.732,662.642c-10.4,9.123-29.87,6.423-45.753-11.968A125.236,125.236,0,0,0,178.9,713c-24.377-7.444-44.583-27-57.172-50.356Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="path_58"
              data-name="path 58"
              d="M237.393,639.362q25.914,39.079,11.275,55.9a127.245,127.245,0,0,0,24.961-19.706Z"
              fill-rule="evenodd"
              fill="url(#linear-gradient-12)"
            />
          </g>
        </g>
      </g>
    </g>
  </>,
  { width: 280, height: 280, fill: "none", style: { height: "50px" } },
);

// const
export const CollabLogo = <CollabLogoSvg />;

// custom
export const SelectionIcon = <SelectionSvg />;

// tabler-icons: square
export const RectangleIcon = <RectangleSvg />;
export const DiagramIcon = <DiagramSvg />;
// tabler-icons: square-rotated
export const DiamondIcon = <DiamondSvg />;

// tabler-icons: circle
export const EllipseIcon = <EllipseSvg />;

// tabler-icons: arrow-narrow-right
export const ArrowIcon = <ArrowSvg />;

// custom?
export const LineIcon = <LineSvg />;

export const PenModeIcon = createIcon(
  <g strokeWidth="1.25">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z"></path>
    <path d="M16 7h4"></path>
    <path d="M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3"></path>
  </g>,
  tablerIconProps,
);

// modified tabler-icons: pencil
export const PenIcon = <PenSvg />;
export const MagicPenIcon = <MagicPenSvg />;
export const PencilIcon = <PencilSvg />;
export const TranslateIcon = <TranslateSvg width={20} height={20} />;
export const RequestAIIcon = <RequestAISvg width={20} height={20} />;
export const RequestAIColorIcon = <RequestAIColorSvg />;
// tabler-icons: typography
export const TextIcon = <TextSvg />;
export const MagicTextIcon = <MagicTextSvg />;
export const ColorPickerIcon = <ColorPicker />;

// modified tabler-icons: photo
export const ImageIcon = createIcon(
  <g strokeWidth="1.25">
    <path d="M12.5 6.667h.01" />
    <path d="M4.91 2.625h10.18a2.284 2.284 0 0 1 2.285 2.284v10.182a2.284 2.284 0 0 1-2.284 2.284H4.909a2.284 2.284 0 0 1-2.284-2.284V4.909a2.284 2.284 0 0 1 2.284-2.284Z" />
    <path d="m3.333 12.5 3.334-3.333c.773-.745 1.726-.745 2.5 0l4.166 4.166" />
    <path d="m11.667 11.667.833-.834c.774-.744 1.726-.744 2.5 0l1.667 1.667" />
  </g>,
  modifiedTablerIconProps,
);

export const ImageSearchIcon = <ImageSearchSvg />;

export const ImageGalleryIcon = <ImageSvg />;

export const PageListIcon = <PagesSvg />;

export const SaveToIcon = <SaveToSvg />;
export const SaveIcon = <SaveSvg />;

export const ZoomInIcon = <ZoomInSvg />;

export const ZoomOutIcon = <ZoomOutSvg />;

export const TrashIcon = <TrashSvg />;
export const ResetIcon = <ResetSvg />;

export const DuplicateIcon = <DuplicateSvg />;

export const TranslateNoColourIcon = <TranslateNoColourSvg />;
export const SendIcon = <SendSvg />;
export const BlueSendIcon = <BlueSendSvg />;
export const AiUploadImgIcon = <AiUploadImgSvg />;

export const MoonIcon = createIcon(
  <path
    clipRule="evenodd"
    d="M10 2.5h.328a6.25 6.25 0 0 0 6.6 10.372A7.5 7.5 0 1 1 10 2.493V2.5Z"
    stroke="currentColor"
  />,
  modifiedTablerIconProps,
);

export const SunIcon = createIcon(
  <g
    stroke="currentColor"
    strokeWidth="1.25"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM10 4.167V2.5M14.167 5.833l1.166-1.166M15.833 10H17.5M14.167 14.167l1.166 1.166M10 15.833V17.5M5.833 14.167l-1.166 1.166M5 10H3.333M5.833 5.833 4.667 4.667" />
  </g>,
  modifiedTablerIconProps,
);

export const HamburgerMenuIcon = createIcon(
  <g strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="4" y1="6" x2="20" y2="6"></line>
    <line x1="4" y1="12" x2="20" y2="12"></line>
    <line x1="4" y1="18" x2="20" y2="18"></line>
  </g>,
  tablerIconProps,
);

export const ExportIcon = createIcon(
  <path
    strokeWidth="1.25"
    d="M3.333 14.167v1.666c0 .92.747 1.667 1.667 1.667h10c.92 0 1.667-.746 1.667-1.667v-1.666M5.833 9.167 10 13.333l4.167-4.166M10 3.333v10"
  />,
  modifiedTablerIconProps,
);

export const QrCodeIcon = createIcon(
  <path
    d="M224 416.096V224l192-0.096 0.096 192.096L224 416.096zM416.096 160H223.904A64 64 0 0 0 160 223.904v192.192A64 64 0 0 0 223.904 480h192.192A64 64 0 0 0 480 416.096V223.904A64 64 0 0 0 416.096 160zM224 800.096V608l192-0.096 0.096 192.096L224 800.096zM416.096 544H223.904A64 64 0 0 0 160 607.904v192.192A64 64 0 0 0 223.904 864h192.192A64 64 0 0 0 480 800.096v-192.192A64 64 0 0 0 416.096 544zM608 416.096V224l192-0.096 0.096 192.096-192.096 0.096zM800.096 160h-192.192A64 64 0 0 0 544 223.904v192.192A64 64 0 0 0 607.904 480h192.192A64 64 0 0 0 864 416.096V223.904A64 64 0 0 0 800.096 160zM704 608a32 32 0 0 0-32 32v192a32 32 0 0 0 64 0v-192a32 32 0 0 0-32-32M576 608a32 32 0 0 0-32 32v192a32 32 0 0 0 64 0v-192a32 32 0 0 0-32-32M832 544a32 32 0 0 0-32 32v256a32 32 0 0 0 64 0v-256a32 32 0 0 0-32-32"
    fill="#3E3A39"
    p-id="3026"
  ></path>,
  { width: 16, height: 16, ...tablerIconCustomProps },
);

export const QrScanIcon = createIcon(
  <path
    d="M224 416.096V224l192-0.096 0.096 192.096L224 416.096zM416.096 160H223.904A64 64 0 0 0 160 223.904v192.192A64 64 0 0 0 223.904 480h192.192A64 64 0 0 0 480 416.096V223.904A64 64 0 0 0 416.096 160zM224 800.096V608l192-0.096 0.096 192.096L224 800.096zM416.096 544H223.904A64 64 0 0 0 160 607.904v192.192A64 64 0 0 0 223.904 864h192.192A64 64 0 0 0 480 800.096v-192.192A64 64 0 0 0 416.096 544zM608 416.096V224l192-0.096 0.096 192.096-192.096 0.096zM800.096 160h-192.192A64 64 0 0 0 544 223.904v192.192A64 64 0 0 0 607.904 480h192.192A64 64 0 0 0 864 416.096V223.904A64 64 0 0 0 800.096 160zM704 608a32 32 0 0 0-32 32v192a32 32 0 0 0 64 0v-192a32 32 0 0 0-32-32M576 608a32 32 0 0 0-32 32v192a32 32 0 0 0 64 0v-192a32 32 0 0 0-32-32M832 544a32 32 0 0 0-32 32v256a32 32 0 0 0 64 0v-256a32 32 0 0 0-32-32"
    fill="#3E3A39"
    p-id="3026"
  ></path>,
  { width: 16, height: 16, ...tablerIconCustomProps },
);

export const DriveIcon = <DriveSvg />;

export const HelpIcon = createIcon(
  <g strokeWidth="1.5">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="12" y1="17" x2="12" y2="17.01"></line>
    <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4"></path>
  </g>,
  tablerIconProps,
);

export const ExternalLinkIcon = createIcon(
  <path
    strokeWidth="1.25"
    d="M9.167 5.833H5.833c-1.254 0-2.5 1.282-2.5 2.5v5.834c0 1.283 1.252 2.5 2.5 2.5h5.834c1.251 0 2.5-1.25 2.5-2.5v-3.334M8.333 11.667l8.334-8.334M12.5 3.333h4.167V7.5"
  />,
  modifiedTablerIconProps,
);

export const GithubIcon = createIcon(
  <path
    d="M7.5 15.833c-3.583 1.167-3.583-2.083-5-2.5m10 4.167v-2.917c0-.833.083-1.166-.417-1.666 2.334-.25 4.584-1.167 4.584-5a3.833 3.833 0 0 0-1.084-2.667 3.5 3.5 0 0 0-.083-2.667s-.917-.25-2.917 1.084a10.25 10.25 0 0 0-5.166 0C5.417 2.333 4.5 2.583 4.5 2.583a3.5 3.5 0 0 0-.083 2.667 3.833 3.833 0 0 0-1.084 2.667c0 3.833 2.25 4.75 4.584 5-.5.5-.5 1-.417 1.666V17.5"
    strokeWidth="1.25"
  />,
  modifiedTablerIconProps,
);

export const DiscordIcon = createIcon(
  <g strokeWidth="1.25">
    <path d="M7.5 10.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM12.5 10.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM6.25 6.25c2.917-.833 4.583-.833 7.5 0M5.833 13.75c2.917.833 5.417.833 8.334 0" />
    <path d="M12.917 14.167c0 .833 1.25 2.5 1.666 2.5 1.25 0 2.361-1.39 2.917-2.5.556-1.39.417-4.861-1.25-9.584-1.214-.846-2.5-1.116-3.75-1.25l-.833 2.084M7.083 14.167c0 .833-1.13 2.5-1.526 2.5-1.191 0-2.249-1.39-2.778-2.5-.529-1.39-.397-4.861 1.19-9.584 1.157-.846 2.318-1.116 3.531-1.25l.833 2.084" />
  </g>,
  modifiedTablerIconProps,
);

export const checkIcon = createIcon(
  <polyline fill="none" stroke="currentColor" points="20 6 9 17 4 12" />,
  {
    width: 24,
    height: 24,
  },
);

export const LinkIcon = createIcon(
  <g strokeWidth="1.25">
    <path d="M8.333 11.667a2.917 2.917 0 0 0 4.167 0l3.333-3.334a2.946 2.946 0 1 0-4.166-4.166l-.417.416" />
    <path d="M11.667 8.333a2.917 2.917 0 0 0-4.167 0l-3.333 3.334a2.946 2.946 0 0 0 4.166 4.166l.417-.416" />
  </g>,
  modifiedTablerIconProps,
);

export const save = createIcon(
  "M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z",
  { width: 448, height: 512 },
);

export const saveAs = createIcon(
  "M252 54L203 8a28 27 0 00-20-8H28C12 0 0 12 0 27v195c0 15 12 26 28 26h204c15 0 28-11 28-26V73a28 27 0 00-8-19zM130 213c-21 0-37-16-37-36 0-19 16-35 37-35 20 0 37 16 37 35 0 20-17 36-37 36zm56-169v56c0 4-4 6-7 6H44c-4 0-7-2-7-6V42c0-4 3-7 7-7h133l4 2 3 2a7 7 0 012 5z M296 201l87 95-188 205-78 9c-10 1-19-8-18-20l9-84zm141-14l-41-44a31 31 0 00-46 0l-38 41 87 95 38-42c13-14 13-36 0-50z",
  { width: 448, height: 512 },
);

// tabler-icon: folder
export const LoadIcon = createIcon(
  <path
    d="m9.257 6.351.183.183H15.819c.34 0 .727.182 1.051.506.323.323.505.708.505 1.05v5.819c0 .316-.183.7-.52 1.035-.337.338-.723.522-1.037.522H4.182c-.352 0-.74-.181-1.058-.5-.318-.318-.499-.705-.499-1.057V5.182c0-.351.181-.736.5-1.054.32-.321.71-.503 1.057-.503H6.53l2.726 2.726Z"
    strokeWidth="1.25"
  />,
  modifiedTablerIconProps,
);

export const clipboard = createIcon(
  "M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z",
  { width: 384, height: 512 },
);

export const palette = createIcon(
  "M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z",
);

export const ExportImageIcon = createIcon(
  <g strokeWidth="1.25">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 8h.01"></path>
    <path d="M12 20h-5a3 3 0 0 1 -3 -3v-10a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v5"></path>
    <path d="M4 15l4 -4c.928 -.893 2.072 -.893 3 0l4 4"></path>
    <path d="M14 14l1 -1c.617 -.593 1.328 -.793 2.009 -.598"></path>
    <path d="M19 16v6"></path>
    <path d="M22 19l-3 3l-3 -3"></path>
  </g>,
  tablerIconProps,
);

export const CanvasBackgroundIcon = createIcon(
  <g strokeWidth="1.25">
    <path d="M30,1H12.123a.981.981,0,0,0-.306,0H2A1,1,0,0,0,1,2v9.694a.985.985,0,0,0,0,.612v8.579a.983.983,0,0,0,0,.23V30a1.01,1.01,0,0,0,.077.382,1,1,0,0,0,.541.541A1.01,1.01,0,0,0,2,31H30a1,1,0,0,0,1-1V2A1,1,0,0,0,30,1ZM13.392,29,29,13.392v6.134L19.526,29ZM4.414,29,29,4.415v6.149L10.563,29ZM3,12.354,12.354,3h6.21L3,18.564Zm0,9.038L21.392,3h6.194L3,27.586ZM9.526,3,3,9.526V3ZM22.354,29,29,22.354V29Z" />
  </g>,
  { width: 40, height: 40 },
);

export const exportToFileIcon = createIcon(
  "M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z",
  { width: 512, height: 512 },
);

export const zoomIn = createIcon(
  "M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z",
  { width: 448, height: 512 },
);

export const zoomOut = createIcon(
  "M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z",
  { width: 448, height: 512 },
);

export const done = createIcon(
  "M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z",
);

export const menu = createIcon(
  "M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z",
);

export const UndoIcon = <UndoSvg />;

export const RedoIcon = <RedoSvg />;
export const GustureIcon = <GustureSvg />;
export const questionCircle = createIcon(
  "M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z",
  { mirror: true },
);

export const share = createIcon(
  <path
    d="M5 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 7.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM7.25 8.917l5.5-2.834M7.25 11.083l5.5 2.834"
    strokeWidth="1.5"
  />,
  modifiedTablerIconProps,
);

export const shareIOS = createIcon(
  "M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z",
  { width: 24, height: 24 },
);

export const shareWindows = createIcon(
  <>
    <path
      fill="currentColor"
      d="M40 5.6v6.1l-4.1.7c-8.9 1.4-16.5 6.9-20.6 15C13 32 10.9 43 12.4 43c.4 0 2.4-1.3 4.4-3 5-3.9 12.1-7 18.2-7.7l5-.6v12.8l11.2-11.3L62.5 22 51.2 10.8 40-.5v6.1zm10.2 22.6L44 34.5v-6.8l-6.9.6c-3.9.3-9.8 1.7-13.2 3.1-3.5 1.4-6.5 2.4-6.7 2.2-.9-1 3-7.5 6.4-10.8C28 18.6 34.4 16 40.1 16c3.7 0 3.9-.1 3.9-3.2V9.5l6.2 6.3 6.3 6.2-6.3 6.2z"
    />
    <path
      stroke="currentColor"
      fill="currentColor"
      d="M0 36v20h48v-6.2c0-6 0-6.1-2-4.3-1.1 1-2 2.9-2 4.2V52H4V34c0-17.3-.1-18-2-18s-2 .7-2 20z"
    />
  </>,
  { width: 64, height: 64 },
);

// Icon imported form Storybook
// Storybook is licensed under MIT https://github.com/storybookjs/storybook/blob/next/LICENSE
export const resetZoom = createIcon(
  <path
    stroke="currentColor"
    strokeWidth="40"
    fill="currentColor"
    d="M148 560a318 318 0 0 0 522 110 316 316 0 0 0 0-450 316 316 0 0 0-450 0c-11 11-21 22-30 34v4h47c25 0 46 21 46 46s-21 45-46 45H90c-13 0-25-6-33-14-9-9-14-20-14-33V156c0-25 20-45 45-45s45 20 45 45v32l1 1a401 401 0 0 1 623 509l212 212a42 42 0 0 1-59 59L698 757A401 401 0 0 1 65 570a42 42 0 0 1 83-10z"
  />,
  { width: 1024 },
);

export const BringForwardIcon = createIcon(
  <>
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M13.889 4.167H8.333c-.767 0-1.389.622-1.389 1.389v5.555c0 .767.622 1.389 1.39 1.389h5.555c.767 0 1.389-.622 1.389-1.389V5.556c0-.767-.622-1.39-1.39-1.39Z"
        fill="currentColor"
      />
      <path d="M12.5 12.5v1.389a1.389 1.389 0 0 1-1.389 1.389H5.556a1.389 1.389 0 0 1-1.39-1.39V8.334a1.389 1.389 0 0 1 1.39-1.389h1.388" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const SendBackwardIcon = createIcon(
  <>
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.944 12.5H12.5v1.389a1.389 1.389 0 0 1-1.389 1.389H5.556a1.389 1.389 0 0 1-1.39-1.39V8.334a1.389 1.389 0 0 1 1.39-1.389h1.388"
        fill="currentColor"
      />
      <path d="M13.889 4.167H8.333c-.767 0-1.389.621-1.389 1.389v5.555c0 .767.622 1.389 1.39 1.389h5.555c.767 0 1.389-.622 1.389-1.389V5.556c0-.768-.622-1.39-1.39-1.39Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const BringToFrontIcon = createIcon(
  <>
    <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.25">
      <path
        d="M8.775 6.458h2.45a2.316 2.316 0 0 1 2.317 2.316v2.452a2.316 2.316 0 0 1-2.316 2.316H8.774a2.316 2.316 0 0 1-2.317-2.316V8.774a2.316 2.316 0 0 1 2.317-2.316Z"
        fill="currentColor"
      />
      <path d="M5.441 9.792h2.451a2.316 2.316 0 0 1 2.316 2.316v2.45a2.316 2.316 0 0 1-2.316 2.317h-2.45a2.316 2.316 0 0 1-2.317-2.316v-2.451a2.316 2.316 0 0 1 2.316-2.316ZM12.108 3.125h2.45a2.316 2.316 0 0 1 2.317 2.316v2.451a2.316 2.316 0 0 1-2.316 2.316h-2.451a2.316 2.316 0 0 1-2.316-2.316v-2.45a2.316 2.316 0 0 1 2.316-2.317Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const SendToBackIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M5.441 9.792h2.451a2.316 2.316 0 0 1 2.316 2.316v2.45a2.316 2.316 0 0 1-2.316 2.317h-2.45a2.316 2.316 0 0 1-2.317-2.316v-2.451a2.316 2.316 0 0 1 2.316-2.316Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <path
        d="M5.441 9.792h2.451a2.316 2.316 0 0 1 2.316 2.316v2.45a2.316 2.316 0 0 1-2.316 2.317h-2.45a2.316 2.316 0 0 1-2.317-2.316v-2.451a2.316 2.316 0 0 1 2.316-2.316Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <mask id="SendToBackIcon" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.167 5.833v2.06a2.941 2.941 0 0 0 2.94 2.94h2.06v.393a2.941 2.941 0 0 1-2.941 2.94h-.393v-2.058a2.941 2.941 0 0 0-2.94-2.941h-2.06v-.393a2.941 2.941 0 0 1 2.942-2.94h.392Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.167 5.833v2.06a2.941 2.941 0 0 0 2.94 2.94h2.06v.393a2.941 2.941 0 0 1-2.941 2.94h-.393v-2.058a2.941 2.941 0 0 0-2.94-2.941h-2.06v-.393a2.941 2.941 0 0 1 2.942-2.94h.392Z"
        fill="currentColor"
      />
      <path
        d="M9.167 5.833h1.25v-1.25h-1.25v1.25Zm5 5h1.25v-1.25h-1.25v1.25Zm-3.334 3.334h-1.25v1.25h1.25v-1.25Zm-5-5h-1.25v1.25h1.25v-1.25Zm2.084-3.334v2.06h2.5v-2.06h-2.5Zm0 2.06a4.191 4.191 0 0 0 4.19 4.19v-2.5a1.691 1.691 0 0 1-1.69-1.69h-2.5Zm4.19 4.19h2.06v-2.5h-2.06v2.5Zm.81-1.25v.393h2.5v-.393h-2.5Zm0 .393c0 .933-.758 1.69-1.691 1.69v2.5a4.191 4.191 0 0 0 4.19-4.19h-2.5Zm-1.691 1.69h-.393v2.5h.393v-2.5Zm.857 1.25v-2.058h-2.5v2.059h2.5Zm0-2.058a4.191 4.191 0 0 0-4.19-4.191v2.5c.933 0 1.69.757 1.69 1.69h2.5Zm-4.19-4.191h-2.06v2.5h2.06v-2.5Zm-.81 1.25v-.393h-2.5v.393h2.5Zm0-.393c0-.934.758-1.69 1.692-1.69v-2.5a4.191 4.191 0 0 0-4.192 4.19h2.5Zm1.692-1.69h.392v-2.5h-.392v2.5Z"
        fill="currentColor"
        mask="url(#SendToBackIcon)"
      />
      <path
        d="M12.108 3.125h2.45a2.316 2.316 0 0 1 2.317 2.316v2.451a2.316 2.316 0 0 1-2.316 2.316h-2.451a2.316 2.316 0 0 1-2.316-2.316v-2.45a2.316 2.316 0 0 1 2.316-2.317Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

//
// Align action icons created from scratch to match those of z-index actions
// Note: vertical align icons are flipped so the larger item is always the
// first one the user sees. Horizontal align icons should not be flipped since
// that would make them lie about their function.
//
export const AlignTopIcon = <AlignTopSvg />;
export const AlignBottomIcon = <AlignBottomSvg />;
export const AlignLeftIcon = <AlignLeftSvg />;

export const AlignRightIcon = <AlignRightSvg />;

export const DistributeHorizontallyIcon = createIcon(
  <>
    <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.25">
      <path
        d="M16.667 3.333v13.334M3.333 3.333v13.334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.375 10.208v-.416c0-.92-.746-1.667-1.667-1.667H7.292c-.92 0-1.667.746-1.667 1.667v.416c0 .92.746 1.667 1.667 1.667h5.416c.92 0 1.667-.746 1.667-1.667Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const DistributeVerticallyIcon = createIcon(
  <>
    <g clipPath="url(#a)" stroke="currentColor" strokeWidth="1.25">
      <path
        d="M3.333 3.333h13.334M3.333 16.667h13.334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.208 5.625h-.416c-.92 0-1.667.746-1.667 1.667v5.416c0 .92.746 1.667 1.667 1.667h.416c.92 0 1.667-.746 1.667-1.667V7.292c0-.92-.746-1.667-1.667-1.667Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const CenterVerticallyIcon = <CenterVerticallySvg />;

export const CenterHorizontallyIcon = <CenterHorizontallySvg />;

export const CollaborateIcon = <CollaborateSvg />;

// not mirrored because it's inspired by a playback control, which is always RTL
export const start = createIcon(
  "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z",
);

export const stop = createIcon(
  "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm96 328c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h160c8.8 0 16 7.2 16 16v160z",
);

export const CloseIcon = createIcon(
  <>
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 5 5 15M5 5l10 10" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const back = createIcon(
  "M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z",
  { width: 320, height: 512, style: { marginLeft: "-0.2rem" }, mirror: true },
);

export const clone = createIcon(
  "M464 0c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48H176c-26.51 0-48-21.49-48-48V48c0-26.51 21.49-48 48-48h288M176 416c-44.112 0-80-35.888-80-80V128H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48v-48H176z",
  { mirror: true },
);

// modified https://feathericons.com/?query=shield
export const shield = createIcon(
  "M11.553 22.894a.998.998 0 00.894 0s3.037-1.516 5.465-4.097C19.616 16.987 21 14.663 21 12V5a1 1 0 00-.649-.936l-8-3a.998.998 0 00-.702 0l-8 3A1 1 0 003 5v7c0 2.663 1.384 4.987 3.088 6.797 2.428 2.581 5.465 4.097 5.465 4.097zm-1.303-8.481l6.644-6.644a.856.856 0 111.212 1.212l-7.25 7.25a.856.856 0 01-1.212 0l-3.75-3.75a.856.856 0 111.212-1.212l3.144 3.144z",
  { width: 24 },
);

export const file = createIcon(
  "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z",
  { width: 384, height: 512 },
);

// TODO barnabasmolnar/editor-redesign
// couldn't find a new icon for this
export const GroupIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <>
      <path d="M25 26H111V111H25" fill={iconFillColor(theme)} />
      <path
        d="M25 111C25 80.2068 25 49.4135 25 26M25 26C48.6174 26 72.2348 26 111 26H25ZM25 26C53.3671 26 81.7343 26 111 26H25ZM111 26C111 52.303 111 78.606 111 111V26ZM111 26C111 51.2947 111 76.5893 111 111V26ZM111 111C87.0792 111 63.1585 111 25 111H111ZM111 111C87.4646 111 63.9293 111 25 111H111ZM25 111C25 81.1514 25 51.3028 25 26V111Z"
        stroke={iconFillColor(theme)}
        strokeWidth="2"
      />
      <path d="M100 100H160V160H100" fill={iconFillColor(theme)} />
      <path
        d="M100 160C100 144.106 100 128.211 100 100M100 100C117.706 100 135.412 100 160 100H100ZM100 100C114.214 100 128.428 100 160 100H100ZM160 100C160 120.184 160 140.369 160 160V100ZM160 100C160 113.219 160 126.437 160 160V100ZM160 160C145.534 160 131.068 160 100 160H160ZM160 160C143.467 160 126.934 160 100 160H160ZM100 160C100 143.661 100 127.321 100 100V160Z"
        stroke={iconFillColor(theme)}
        strokeWidth="2"
      />
      <g
        fill={handlerColor(theme)}
        stroke={iconFillColor(theme)}
        strokeWidth="6"
      >
        <rect x="2.5" y="2.5" width="30" height="30" />
        <rect x="2.5" y="149.5" width="30" height="30" />
        <rect x="147.5" y="149.5" width="30" height="30" />
        <rect x="147.5" y="2.5" width="30" height="30" />
      </g>
    </>,
    { width: 182, height: 182, mirror: true },
  ),
);

export const UngroupIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <>
      <path d="M25 26H111V111H25" fill={iconFillColor(theme)} />
      <path
        d="M25 111C25 80.2068 25 49.4135 25 26M25 26C48.6174 26 72.2348 26 111 26H25ZM25 26C53.3671 26 81.7343 26 111 26H25ZM111 26C111 52.303 111 78.606 111 111V26ZM111 26C111 51.2947 111 76.5893 111 111V26ZM111 111C87.0792 111 63.1585 111 25 111H111ZM111 111C87.4646 111 63.9293 111 25 111H111ZM25 111C25 81.1514 25 51.3028 25 26V111Z"
        stroke={iconFillColor(theme)}
        strokeWidth="2"
      />
      <path d="M100 100H160V160H100" fill={iconFillColor(theme)} />
      <path
        d="M100 160C100 144.106 100 128.211 100 100M100 100C117.706 100 135.412 100 160 100H100ZM100 100C114.214 100 128.428 100 160 100H100ZM160 100C160 120.184 160 140.369 160 160V100ZM160 100C160 113.219 160 126.437 160 160V100ZM160 160C145.534 160 131.068 160 100 160H160ZM160 160C143.467 160 126.934 160 100 160H160ZM100 160C100 143.661 100 127.321 100 100V160Z"
        stroke={iconFillColor(theme)}
        strokeWidth="2"
      />
      <g
        fill={handlerColor(theme)}
        stroke={iconFillColor(theme)}
        strokeWidth="6"
      >
        <rect x="2.5" y="2.5" width="30" height="30" />
        <rect x="78.5" y="149.5" width="30" height="30" />
        <rect x="147.5" y="149.5" width="30" height="30" />
        <rect x="147.5" y="78.5" width="30" height="30" />
        <rect x="105.5" y="2.5" width="30" height="30" />
        <rect x="2.5" y="102.5" width="30" height="30" />
      </g>
    </>,
    { width: 182, height: 182, mirror: true },
  ),
);

export const FillHachureIcon = createIcon(
  <>
    <path
      d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <mask
      id="FillHachureIcon"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={16}
      height={16}
    >
      <path
        d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </mask>
    <g mask="url(#FillHachureIcon)">
      <path
        d="M2.258 15.156 15.156 2.258M7.324 20.222 20.222 7.325m-20.444 5.35L12.675-.222m-8.157 18.34L17.416 5.22"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>,
  modifiedTablerIconProps,
);

export const FillCrossHatchIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <mask
        id="FillCrossHatchIcon"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={-1}
        y={-1}
        width={22}
        height={22}
      >
        <path
          d="M2.426 15.044 15.044 2.426M7.383 20 20 7.383M0 12.617 12.617 0m-7.98 17.941L17.256 5.324m-2.211 12.25L2.426 4.956M20 12.617 7.383 0m5.234 20L0 7.383m17.941 7.98L5.324 2.745"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#FillCrossHatchIcon)">
        <path
          d="M14.121 2H5.88A3.879 3.879 0 0 0 2 5.879v8.242A3.879 3.879 0 0 0 5.879 18h8.242A3.879 3.879 0 0 0 18 14.121V5.88A3.879 3.879 0 0 0 14.121 2Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const FillSolidIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M4.91 2.625h10.18a2.284 2.284 0 0 1 2.285 2.284v10.182a2.284 2.284 0 0 1-2.284 2.284H4.909a2.284 2.284 0 0 1-2.284-2.284V4.909a2.284 2.284 0 0 1 2.284-2.284Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  { ...modifiedTablerIconProps, fill: "currentColor" },
);

export const StrokeWidthBaseIcon = <StrokeWidthBaseSvg />;

export const StrokeWidthBoldIcon = createIcon(
  <path
    d="M5 10h10"
    stroke="currentColor"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  modifiedTablerIconProps,
);

export const StrokeWidthExtraBoldIcon = createIcon(
  <path
    d="M5 10h10"
    stroke="currentColor"
    strokeWidth="3.75"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  modifiedTablerIconProps,
);

export const StrokeStyleSolidIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <path
      d="M6 10H34"
      stroke={iconFillColor(theme)}
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
    />,
    {
      width: 40,
      height: 20,
    },
  ),
);

export const StrokeStyleDashedIcon = <StrokeStyleDashedSvg />;

// tabler-icons: line-dotted
export const StrokeStyleDottedIcon = <StrokeStyleDottedSvg />;
export const StrokeIcon = <StrokeSvg />;

export const SloppinessArchitectIcon = createIcon(
  <path
    d="M2.5 12.038c1.655-.885 5.9-3.292 8.568-4.354 2.668-1.063.101 2.821 1.32 3.104 1.218.283 5.112-1.814 5.112-1.814"
    strokeWidth="1.25"
  />,
  modifiedTablerIconProps,
);

export const SloppinessArtistIcon = createIcon(
  <path
    d="M2.5 12.563c1.655-.886 5.9-3.293 8.568-4.355 2.668-1.062.101 2.822 1.32 3.105 1.218.283 5.112-1.814 5.112-1.814m-13.469 2.23c2.963-1.586 6.13-5.62 7.468-4.998 1.338.623-1.153 4.11-.132 5.595 1.02 1.487 6.133-1.43 6.133-1.43"
    strokeWidth="1.25"
  />,
  modifiedTablerIconProps,
);

export const SloppinessCartoonistIcon = createIcon(
  <path
    d="M2.5 11.936c1.737-.879 8.627-5.346 10.42-5.268 1.795.078-.418 5.138.345 5.736.763.598 3.53-1.789 4.235-2.147M2.929 9.788c1.164-.519 5.47-3.28 6.987-3.114 1.519.165 1 3.827 2.121 4.109 1.122.281 3.839-2.016 4.606-2.42"
    strokeWidth="1.25"
  />,
  modifiedTablerIconProps,
);

export const EdgeSharpIcon = createIcon(
  <svg strokeWidth="1.5">
    <path d="M3.33334 9.99998V6.66665C3.33334 6.04326 3.33403 4.9332 3.33539 3.33646C4.95233 3.33436 6.06276 3.33331 6.66668 3.33331H10" />
    <path d="M13.3333 3.33331V3.34331" />
    <path d="M16.6667 3.33331V3.34331" />
    <path d="M16.6667 6.66669V6.67669" />
    <path d="M16.6667 10V10.01" />
    <path d="M3.33334 13.3333V13.3433" />
    <path d="M16.6667 13.3333V13.3433" />
    <path d="M3.33334 16.6667V16.6767" />
    <path d="M6.66666 16.6667V16.6767" />
    <path d="M10 16.6667V16.6767" />
    <path d="M13.3333 16.6667V16.6767" />
    <path d="M16.6667 16.6667V16.6767" />
  </svg>,
  modifiedTablerIconProps,
);

// tabler-icons: border-radius
export const EdgeRoundIcon = createIcon(
  <g
    strokeWidth="1.5"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M4 12v-4a4 4 0 0 1 4 -4h4" />
    <line x1="16" y1="4" x2="16" y2="4.01" />
    <line x1="20" y1="4" x2="20" y2="4.01" />
    <line x1="20" y1="8" x2="20" y2="8.01" />
    <line x1="20" y1="12" x2="20" y2="12.01" />
    <line x1="4" y1="16" x2="4" y2="16.01" />
    <line x1="20" y1="16" x2="20" y2="16.01" />
    <line x1="4" y1="20" x2="4" y2="20.01" />
    <line x1="8" y1="20" x2="8" y2="20.01" />
    <line x1="12" y1="20" x2="12" y2="20.01" />
    <line x1="16" y1="20" x2="16" y2="20.01" />
    <line x1="20" y1="20" x2="20" y2="20.01" />
  </g>,
  tablerIconProps,
);
export const MarkerIcon = <MarkerSvg />;
export const BrushIcon = <BrushSvg />;
export const RubberIcon = (
  <svg
    fill="#000000"
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.013 508.013"
  >
    <g>
      <g>
        <path
          d="M490.3,133.177l-99.5-99.6c-33-33-74-11.4-85.5,0l-287.6,287.7c-23.6,23.6-23.6,61.9,0,85.5l81.1,81.1
   c2.6,2.6,6.2,4.1,10,4.1h102.4c3.7,0,7.3-1.5,10-4.1l269.2-269.2C513.9,195.077,513.9,156.777,490.3,133.177z M205.3,463.777
   h-90.7l-77-77c-12.6-12.6-12.6-33,0-45.5l67.4-67.4l145.1,145.1L205.3,463.777z M470.4,198.677l-200.3,200.3L125,253.877
   l200.3-200.3c6.1-6.1,27-18.5,45.5,0l99.5,99.5C482.9,165.777,482.9,186.177,470.4,198.677z"
        />
      </g>
    </g>
  </svg>
);
export const FlashCollabIcon = <FlashCollabSvg />;

export const FlashCollabShutIcon = <FlashCollabShutSvg />;

export const EnlargeIcon = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <title>enlarge</title>
    <path d="M21 19.031v-14.063h-18v14.063h18zM21 3q0.797 0 1.406 0.609t0.609 1.406v13.969q0 0.797-0.609 1.406t-1.406 0.609h-18q-0.797 0-1.406-0.609t-0.609-1.406v-13.969q0-0.797 0.609-1.406t1.406-0.609h18zM14.016 15.984l-2.016 2.531-2.016-2.531h4.031zM6 9.984v4.031l-2.484-2.016zM18 9.984l2.484 2.016-2.484 2.016v-4.031zM12 5.484l2.016 2.531h-4.031z"></path>
  </svg>
);

export const IconCopy = <CopySvg />;

export const IconUnion = <UnionSvg width={24} height={24} />;

export const IconEnvelope = <EmailSvg />;

export const IconGoogle = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.01111 20.4741V11.7963L1 7.94635V18.6408C1 19.863 1.61111 20.4741 2.83333 20.4741"
      fill="#4285F4"
    />
    <path
      d="M5.76672 11.7353L12.0001 16.4408L18.2334 11.7353V5.74637L12.0001 10.4519L5.76672 5.74637"
      fill="#EA4335"
    />
    <path
      d="M17.9889 20.4741V11.7963L23 7.94635V18.6408C23 19.863 22.3889 20.4741 21.1667 20.4741"
      fill="#34A853"
    />
    <path
      d="M1 8.06861L6.01111 11.9186V5.92972L4.17778 4.52417C2.52778 3.24083 1 4.52417 1 6.11306"
      fill="#C5221F"
    />
    <path
      d="M23 8.06861L17.9889 11.9186V5.92972L19.8222 4.52417C21.4722 3.24083 23 4.52417 23 6.11306"
      fill="#FBBC04"
    />
  </svg>
);

export const IconCloseWin = <CloseWinSvg />;

export const AppIconCloseWin = <CloseWinSvg width={16} height={16} />;

export const IconClose = <CloseSvg />;
export const IconCloseWhite = <CloseWhiteSvg />;

export const IconOffice365 = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 1.5H9C8.17157 1.5 7.5 2.17157 7.5 3V21C7.5 21.8284 8.17157 22.5 9 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V3C22.5 2.17157 21.8284 1.5 21 1.5Z"
      fill="#1066B5"
    />
    <path
      d="M21 1.5H9C8.17157 1.5 7.5 2.17157 7.5 3V21C7.5 21.8284 8.17157 22.5 9 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V3C22.5 2.17157 21.8284 1.5 21 1.5Z"
      fill="url(#paint0_linear_1901_2645)"
    />
    <path d="M15 3.75H7.5V11.25H15V3.75Z" fill="#32A9E7" />
    <path d="M15 11.25H7.5V18.75H15V11.25Z" fill="#167EB4" />
    <path d="M22.5 11.25H15V18.75H22.5V11.25Z" fill="#32A9E7" />
    <path d="M22.5 3.75H15V11.25H22.5V3.75Z" fill="#58D9FD" />
    <mask
      id="mask0_1901_2645"
      maskUnits="userSpaceOnUse"
      x="6"
      y="10"
      width="18"
      height="13"
    >
      <path
        d="M6 10.5H22.5C23.3284 10.5 24 11.1716 24 12V21C24 21.8284 23.3284 22.5 22.5 22.5H7.5C6.67157 22.5 6 21.8284 6 21V10.5Z"
        fill="url(#paint1_linear_1901_2645)"
      />
    </mask>
    <g mask="url(#mask0_1901_2645)">
      <path d="M24 10.5V13.5H22.5V10.5H24Z" fill="#135298" />
      <path
        d="M24 22.5V12L5.25 22.5H24Z"
        fill="url(#paint2_linear_1901_2645)"
      />
      <path d="M6 22.5V12L24.75 22.5H6Z" fill="url(#paint3_linear_1901_2645)" />
    </g>
    <path
      d="M6 9C6 7.75732 7.00736 6.75 8.25 6.75H12.75C13.9927 6.75 15 7.75732 15 9V18C15 19.2427 13.9927 20.25 12.75 20.25H6V9Z"
      fill="black"
      fill-opacity="0.3"
    />
    <path
      d="M12 5.25H1.5C0.671573 5.25 0 5.92157 0 6.75V17.25C0 18.0784 0.671573 18.75 1.5 18.75H12C12.8284 18.75 13.5 18.0784 13.5 17.25V6.75C13.5 5.92157 12.8284 5.25 12 5.25Z"
      fill="url(#paint4_linear_1901_2645)"
    />
    <path
      d="M10.5 12.052V11.9272C10.5 9.76665 8.9454 8.25 6.76186 8.25C4.56646 8.25 3 9.777 3 11.948V12.0728C3 14.2334 4.55458 15.75 6.75 15.75C8.93355 15.75 10.5 14.223 10.5 12.052ZM8.7318 12.0728C8.7318 13.5062 7.92487 14.3684 6.76186 14.3684C5.59889 14.3684 4.78006 13.4854 4.78006 12.052V11.9272C4.78006 10.4938 5.58703 9.63158 6.75 9.63158C7.9011 9.63158 8.7318 10.5146 8.7318 11.948V12.0728Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1901_2645"
        x1="7.5"
        y1="12"
        x2="22.5"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#064484" />
        <stop offset="1" stop-color="#0F65B5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1901_2645"
        x1="6"
        y1="20.0769"
        x2="24"
        y2="20.0769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B366F" />
        <stop offset="1" stop-color="#2657B0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1901_2645"
        x1="24"
        y1="17.25"
        x2="6"
        y2="17.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#44DCFD" />
        <stop offset="0.453125" stop-color="#259ED0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1901_2645"
        x1="6"
        y1="17.25"
        x2="24"
        y2="17.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#259ED0" />
        <stop offset="1" stop-color="#44DCFD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1901_2645"
        x1="0"
        y1="12"
        x2="13.5"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#064484" />
        <stop offset="1" stop-color="#0F65B5" />
      </linearGradient>
    </defs>
  </svg>
);

export const ArrowheadNoneIcon = <ArrowheadNoneSvg />;

export const ArrowheadArrowLeftIcon = <ArrowheadArrowLeftSvg />;

export const ArrowheadArrowRightIcon = <ArrowheadArrowRightSvg />;

export const ArrowheadArrowIcon = React.memo(
  ({ flip = false }: { flip?: boolean }) =>
    createIcon(
      <g
        transform={flip ? "translate(40, 0) scale(-1, 1)" : ""}
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
      >
        <path d="M34 10H6M34 10L27 5M34 10L27 15" />
        <path d="M27.5 5L34.5 10L27.5 15" />
      </g>,
      { width: 40, height: 20 },
    ),
);

export const ArrowheadDotLeftIcon = <ArrowheadDotLeftSvg />;

export const ArrowheadDotRightIcon = <ArrowheadDotRightSvg />;

export const ArrowheadDotIcon = React.memo(
  ({ flip = false }: { flip?: boolean }) =>
    createIcon(
      <g
        stroke="currentColor"
        fill="currentColor"
        transform={flip ? "translate(40, 0) scale(-1, 1)" : ""}
      >
        <path d="M32 10L6 10" strokeWidth={2} />
        <circle r="4" transform="matrix(-1 0 0 1 30 10)" />
      </g>,
      { width: 40, height: 20 },
    ),
);

export const ArrowheadBarIcon = React.memo(
  ({ flip = false }: { flip?: boolean }) =>
    createIcon(
      <g transform={flip ? "translate(40, 0) scale(-1, 1)" : ""}>
        <path
          d="M34 10H5.99996M34 10L34 5M34 10L34 15"
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
        />
      </g>,
      { width: 40, height: 20 },
    ),
);

export const ArrowheadTriangleIcon = React.memo(
  ({ flip = false }: { flip?: boolean }) =>
    createIcon(
      <g
        stroke="currentColor"
        fill="currentColor"
        transform={flip ? "translate(40, 0) scale(-1, 1)" : ""}
      >
        <path d="M32 10L6 10" strokeWidth={2} />
        <path d="M27.5 5.5L34.5 10L27.5 14.5L27.5 5.5" />
      </g>,
      { width: 40, height: 20 },
    ),
);

export const FontSizeSmallIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M14.167 6.667a3.333 3.333 0 0 0-3.334-3.334H9.167a3.333 3.333 0 0 0 0 6.667h1.666a3.333 3.333 0 0 1 0 6.667H9.167a3.333 3.333 0 0 1-3.334-3.334"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const FontSizeMediumIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M5 16.667V3.333L10 15l5-11.667v13.334"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const FontSizeLargeIcon = createIcon(
  <>
    <g clipPath="url(#a)">
      <path
        d="M5.833 3.333v13.334h8.334"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const FontSizeExtraLargeIcon = createIcon(
  <>
    <path
      d="m1.667 3.333 6.666 13.334M8.333 3.333 1.667 16.667M11.667 3.333v13.334h6.666"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  modifiedTablerIconProps,
);

export const FontFamilyNormalIcon = createIcon(
  <>
    <g
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.833 16.667v-10a3.333 3.333 0 0 1 3.334-3.334h1.666a3.333 3.333 0 0 1 3.334 3.334v10M5.833 10.833h8.334" />
    </g>
  </>,
  modifiedTablerIconProps,
);

export const FontFamilyCodeIcon = createIcon(
  <>
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.833 6.667 2.5 10l3.333 3.333M14.167 6.667 17.5 10l-3.333 3.333M11.667 3.333 8.333 16.667" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>,
  modifiedTablerIconProps,
);

export const TextAlignLeftIcon = createIcon(
  <g
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="4" y1="8" x2="20" y2="8" />
    <line x1="4" y1="12" x2="12" y2="12" />
    <line x1="4" y1="16" x2="16" y2="16" />
  </g>,
  tablerIconProps,
);

export const TextAlignCenterIcon = createIcon(
  <g
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="4" y1="8" x2="20" y2="8" />
    <line x1="8" y1="12" x2="16" y2="12" />
    <line x1="6" y1="16" x2="18" y2="16" />
  </g>,
  tablerIconProps,
);

export const TextAlignRightIcon = createIcon(
  <g
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="4" y1="8" x2="20" y2="8" />
    <line x1="10" y1="12" x2="20" y2="12" />
    <line x1="8" y1="16" x2="20" y2="16" />
  </g>,
  tablerIconProps,
);

// tabler-icons: layout-align-top
export const TextAlignTopIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <g
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="4" y1="4" x2="20" y2="4" />
      <rect x="9" y="8" width="6" height="12" rx="2" />
    </g>,
    tablerIconProps,
  ),
);

// tabler-icons: layout-align-bottom
export const TextAlignBottomIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <g
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="4" y1="20" x2="20" y2="20" />
      <rect x="9" y="4" width="6" height="12" rx="2"></rect>
    </g>,
    tablerIconProps,
  ),
);

// tabler-icons: layout-align-middle
export const TextAlignMiddleIcon = React.memo(({ theme }: { theme: Theme }) =>
  createIcon(
    <g
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="4" y1="12" x2="9" y2="12" />
      <line x1="15" y1="12" x2="20" y2="12" />
      <rect x="9" y="6" width="6" height="12" rx="2" />
    </g>,
    tablerIconProps,
  ),
);

export const publishIcon = createIcon(
  <path
    d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"
    fill="currentColor"
  />,
  { width: 640, height: 512 },
);

export const eraser = createIcon(
  <path d="M480 416C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H150.6C133.7 480 117.4 473.3 105.4 461.3L25.37 381.3C.3786 356.3 .3786 315.7 25.37 290.7L258.7 57.37C283.7 32.38 324.3 32.38 349.3 57.37L486.6 194.7C511.6 219.7 511.6 260.3 486.6 285.3L355.9 416H480zM265.4 416L332.7 348.7L195.3 211.3L70.63 336L150.6 416L265.4 416z" />,
);

export const ViewBackgroundIcon = <ViewBackgroundSvg />;

export const ArrowheadIcon = <ArrowheadSvg />;

export const FontSizeIcon = <FontSizeSvg />;

export const FillIcon = <FillSvg />;

export const OpacityIcon = <OpacitySvg />;

export const MutiObjectsIcon = <MutiObjectsSvg />;

export const UngroupStaticIcon = <UngroupStaticSvg />;

export const GroupSettingIcon = <GroupSettingSvg />;

export const StrokeColorIcon = <StrokeColorSvg />;
export const DrawStrokeColorIcon = (fill: string = "black") => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
        fill={fill}
      ></path>
    </svg>
  );
};
export const ProfileIcon = <ProfileSvg width={40} height={40} />;

export const SignOutIcon = <SignOutSvg />;

export const LibMenuIcon = <LibMenuSvg />;

export const RecentlyEditedIcon = <RecentlyEditedSvg />;

export const ScannerCodeIcon = <ScannerCodeSvg />;

export const GoogleIcon = <GoogleSvg />;

export const MicrosoftIcon = <MicrosoftSvg />;

export const FreeIcon = <FreeSvg />;

export const StandardIcon = <StandardSvg />;

export const ProIcon = <ProSvg />;

export const TickIcon = <TickSvg />;

export const SearchIcon = <SearchSvg />;
export const SearchDashboardIcon = <SearchDashboardSvg />;

export const AppSearchIcon = <SearchSvg width={15} height={12} />;

export const DownloadIcon = <DownloadSvg />;

export const ClbFileIcon = <ClbFileSvg />;
export const PngFileIcon = <PngFileSvg />;
export const PdfFileIcon = <PdfFileSvg />;
export const SvgFileIcon = <SvgFileSvg />;
export const MinimizeIcon = <MinimizeSvg />;
export const MinimizeWhiteIcon = <MinimizeWhiteSvg />;
export const GoogleMeetIcon = <GoogleMeetSvg />;
export const GoogleClassroomIcon = <GoogleClassroomSvg />;
export const GoogledDriveIcon = <GoogledDriveSvg />;
export const AiSearchIcon = <AiSearchSvg />;
export const ImageAiIcon = <ImageAiSvg />;
export const GoogledSearchIcon = <GoogledSearchSvg />;
export const SwitchLeftIcon = <SwitchLeftSvg />;
export const SwitchRightIcon = <SwitchRightSvg />;
export const ShareScreenIcon = <ShareScreenSvg />;
export const CloudServiceIcon = <CloudServiceSvg />;
export const OpenIcon = <OpenSvg />;
export const ResizeFloatingIcon = <ResizeMiniSvg />;
export const ResizeMaxIcon = <ResizeMaxSvg />;
export const ResizeNormalIcon = <ResizeNormalSvg />;
export const RefreshIcon = <RefreshSvg />;
export const UpgradeIcon = <UpgradeSvg />;
export const CameraMutedIcon = <CameraMutedSvg />;
export const CameraOnIcon = <CameraOnSvg />;
export const FootballIcon = <FootballSvg />;
export const BackgroundIcon = <BackgroundSvg />;
export const BasketballIcon = <BasketballSvg />;
export const DarkModeIcon = <DarkModeSvg />;
export const EraserIcon = <EraserSvg />;
export const EraserObjectIcon = <EraserObjectSvg />;
export const EraserBigIcon = <EraserBigSvg />;
export const Off50Icon = <Off50Svg />;
export const BestDealIcon = <BestDealSvg />;
export const YoutubeIcon = <YoutubeSvg />;
export const StemIcon = <StemSvg />;
export const SchoolIcon = <SchoolSvg />;
export const TwitterIcon = <TwitterSvg />;
export const LinkedinIcon = <LinkedinSvg />;
export const InstagramIcon = <InstagramSvg />;
export const FacebookIcon = <FacebookSvg />;
export const TemplateIcon = <TemplateSvg />;
export const GoogleDocsIcon = <GoogleDocsSvg />;
export const GoogleSlidesIcon = <GoogleSlidesSvg />;
export const GoogleSheetIcon = <GoogleSheetSvg />;
export const AiIcon = <AiSvg />;
export const ChatIcon = <ChatSvg />;
export const CrossIcon = <CrossSvg />;
export const EyeIcon = <EyeSvg />;
export const BlindIcon = <BlindSvg />;
export const MermaidIcon = <MermaidSvg />;
export const FlowChartIcon = <FlowChartSvg />;
export const DrawioIcon = <DrawioSvg />;
export const NoteIcon = <NoteSvg />;
export const PersonalBoardIcon = <PersonalBoardSvg />;
export const AIBotIcon = <AIBotSvg />;
export const AIBotCloseIcon = <AIBotCloseSvg />;