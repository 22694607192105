import "./FileSaveToModal.scss";

import { AppState, BinaryFileData, BinaryFiles } from "../types";
import { TabList } from "./sidepanel/SidePanel";
import { t } from "../i18n";
import {
  setPageListToStorage,
  getPageListFromStorage,
  setCurrPageToStorage,
  getCurrPageFromStorage,
  delDataFromStorage,
  getPageDataByKeyFromLocalStorage,
  getItemWithExpiration,
  setItemWithExpiration,
} from "../imago-app/data/localStorage";
import { handleGoogleLogin } from "./GoogleDriveApi";
import { getNonDeletedElements } from "../element";
import { LocalData } from "../imago-app/data/LocalData";
import { isInitializedImageElement } from "../element/typeChecks";
import { useState, useEffect } from "react";
import { PagePreview } from "./PagePreview";
import { FileId, NonDeletedImagoElement } from "../element/types";
import { ExportCB } from "./ImageExportDialog";
import { ExportType } from "../scene/types";
import { trackEvent } from "../analytics";
import { exportCanvasNew, downFileFromCanvas } from "../data";
import { googleSignRedirect, muteFSAbortError } from "../utils";
import { isImageFileHandle } from "../data/blob";
import {
  ClbFileIcon,
  GoogleClassroomIcon,
  GoogledDriveIcon,
  PdfFileIcon,
  PngFileIcon,
  SvgFileIcon,
  file,
} from "./icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  FormProps,
  Input,
  Select,
} from "antd";
import { isGoogleMeetAddonAtom } from "./App";
import { useAtom } from "jotai";
import { Modal } from "./Modal";
import clsx from "clsx";
import { Dialog } from "./Dialog";
import Meta from "antd/es/card/Meta";
export const FileSaveToModal: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
  onClose: () => void;
  isMobile: boolean;
}> = ({ appState, setAppState, id, onClose, isMobile }) => {
  return (
    <Dialog title={t("leftMenu.save_to")} onCloseRequest={onClose}>
      <FileSaveToContent appState={appState} onCancel={onClose} />
    </Dialog>
  );
};

export const FileSaveToContent: React.FC<{
  appState: AppState;
  onCancel?: () => void;
}> = ({ appState, onCancel }) => {
  const { userInfo } = appState;
  const generateFileName = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `file-${year}${month}${day}-${hours}${minutes}${seconds}`;
  };

  const [pageList, setPageList] = useState(getPageListFromStorage());
  const [fileName, setFileName] = useState(generateFileName());
  const [uploadToGoogleDrive, setUploadToGoogleDrive] = useState(false);
  const [hasBoardType, setHasBoardType] = useState(false);
  const [hasPngType, setHasPngType] = useState(false);
  const [hasSvgType, setHasSvgType] = useState(false);
  const [hasPdfType, setHasPdfType] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState(
    getItemWithExpiration("google_access_token"),
  );
  const [isDowning, setIsDowning] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isShareToClassroom, setIsShareToGoogleRoom] = useState(true);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [isGoogleMeetAddon, _] = useAtom(isGoogleMeetAddonAtom);
  const [type, setType] = useState("classroom");


  const selectStype = {
    background: "#ccc",
    boxShadow: "inset 0px 3px 3px rgba(0, 0, 0, 0.5)",
  };

  const finishDown = () => {
    setIsDowning(false);
    onCancel && onCancel();
  };

  const onFileTypeChange = (value: string[]) => {
    setHasBoardType(value.indexOf("clb") > -1);
    setHasSvgType(value.indexOf("svg") > -1);
    setHasPdfType(value.indexOf("pdf") > -1);
    setHasPngType(value.indexOf("png") > -1);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.slice(1));
    // const urlParams = new URLSearchParams(window.location.hash);
    const accessToken = urlParams.get("access_token");
    const expiresIn = urlParams.get("expires_in");

    if (accessToken && expiresIn) {
      setItemWithExpiration(
        "google_access_token",
        accessToken,
        parseInt(expiresIn),
      );
      window.location.href = `${window.location.origin}/board`;
    }
  }, [window.location]);

  useEffect(() => {
    if (googleAccessToken) {
      fetch(`/api/v1/courses?token=${googleAccessToken}`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${userInfo?.authorization}`,
        }),
      }).then(async (response) => {
        const data = await response.json();
        if (data.courses) {
          setCourses(data.courses);
        }
      });
    }
  }, [googleAccessToken]);

  return (
    <>
      {" "}
      <Form name="basic" autoComplete="off" layout="vertical">
        <Form.Item
          label={t("labels.fileTitle")}
          name="fileName"
          initialValue={fileName}
          rules={[{ required: true, message: "" }]}
        >
          <Input
            placeholder={t("labels.fileTitle")}
            onChange={(event) => {
              setFileName(event.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          label={t("labels.fileTypeLabel")}
          name="fileType"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            mode="multiple"
            onChange={onFileTypeChange}
            allowClear
            placeholder={t("labels.fileTypeLabel")}
          >
            <Select.Option value="clb">
              {t("labels.boardTypeLabel")}
            </Select.Option>
            <Select.Option value="pdf">
              {t("labels.pdfTypeLabel")}
            </Select.Option>
            <Select.Option value="png">
              {t("labels.pngTypeLabel")}
            </Select.Option>
            {appState.userLicence?.exportToSvgGoogleDriveGoogleClassroom && (
              <Select.Option value="svg">
                {t("labels.svgTypeLabel")}
              </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item label={t("leftMenu.save_to_google")} name="toGoogle">
          {!googleAccessToken || googleAccessToken === "" ? (
            <a
              onClick={() => {
                googleSignRedirect(false, isGoogleMeetAddon);
              }}
            >
              {t("buttons.signIn")}
            </a>
          ) : (
            <Flex gap={20}>
              <Card
                hoverable
                classNames={{ body: "card" }}
                onClick={() => {
                  setType("classroom")
                  setIsShareToGoogleRoom(true)
                  setUploadToGoogleDrive(false)
                }}
                style={{
                  width: 130,
                  ...(type == "classroom" ? selectStype : {}),
                }}
              >
                {GoogleClassroomIcon}
                <span>Classroom</span>
              </Card>
              <Card
                hoverable
                classNames={{ body: "card" }}
                onClick={() => {
                  setType("googleDrive")
                  setIsShareToGoogleRoom(false)
                  setUploadToGoogleDrive(true)
                }}
                style={{
                  width: 130,
                  ...(type == "googleDrive" ? selectStype : {}),
                }}
              >
                {GoogledDriveIcon}
                <span>Drive</span>
              </Card>
            </Flex>
          )}
        </Form.Item>
        {googleAccessToken && isShareToClassroom && (
          <Form.Item label="Select Folder" name="folder">
            <Select
              placeholder="Pick Folder"
              style={{ width: "100%" }}
              onChange={(v) => {
                setSelectedCourseId(v);
                setIsShareToGoogleRoom(true)
                setUploadToGoogleDrive(false)
              }}
              options={courses.map((c: any) => {
                return { label: c.name, value: c.id };
              })}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Flex gap={20}>
            <Button onClick={() => onCancel && onCancel()}>
              {t("buttons.cancel")}
            </Button>
            {isDowning ||
              (!hasBoardType && !hasPdfType && !hasPngType && !hasSvgType) ||
              (isShareToClassroom && !selectedCourseId) ? (
              <Button disabled>{t("buttons.fileSave")}</Button>
            ) : (
              <Button
                type="primary"
                onClick={async () => {
                  if (
                    !hasBoardType &&
                    !hasPdfType &&
                    !hasPngType &&
                    !hasSvgType
                  ) {
                    return;
                  }
                  if (isDowning) {
                    return;
                  }
                  if (uploadToGoogleDrive || isShareToClassroom) {
                    setIsDowning(true);
                  }

                  let files = {};
                  for (let index = 0; index < pageList.length; index++) {
                    const page = pageList[index];
                    const allElements = await LocalData.pagesStorage.get(
                      page.id,
                    );
                    const fileIds =
                      allElements?.reduce((acc, element) => {
                        if (isInitializedImageElement(element)) {
                          return acc.concat(element.fileId);
                        }
                        return acc;
                      }, [] as FileId[]) || [];

                    if (fileIds.length) {
                      const { loadedFiles, erroredFiles } =
                        await LocalData.fileStorage.getFiles(fileIds);
                      if (loadedFiles.length) {
                        const filesMap = loadedFiles.reduce((acc, fileData) => {
                          acc.set(fileData.id, fileData);
                          return acc;
                        }, new Map());
                        files = { ...files, ...Object.fromEntries(filesMap) };
                      }
                    }
                  }

                  await downFileFromCanvas(pageList, files, appState, {
                    exportBackground: appState.exportBackground,
                    name: fileName,
                    viewBackgroundColor: appState.viewBackgroundColor,
                    toGoogleDrive: uploadToGoogleDrive,
                    hasBoardType,
                    hasPngType,
                    hasSvgType,
                    hasPdfType,
                    shareToGoogleClassroom: isShareToClassroom,
                    courseId: selectedCourseId,
                    callback: finishDown,
                  });
                }}
              >
                {t("buttons.fileSave")}
              </Button>
            )}
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};
