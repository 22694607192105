

export const config = {
    google_auth_account: {
        client_id: "505804185250-2tfdqochbc832vqp7pmc1m3er020usma.apps.googleusercontent.com",
        client_secret: "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",
        api_key: "AIzaSyDFLs6vyXRAHGywSmdlwIZ7QrEwFSTVWL4"
    }
}

