import "./AiChat.scss";
import React, { useEffect, useState } from "react";
import { Message, Ollama } from "ollama/dist/browser";
//import ollama from 'ollama/dist/browser';
import { marked } from "marked";
import { Button, Input, List, Space, message, notification } from "antd";
import {
  AiSearchIcon,
  CloseIcon,
  DuplicateIcon,
  IconClose,
  IconCloseWhite,
  IconCopy,
  IconUnion,
  PencilIcon,
  ProfileIcon,
  ResetIcon,
  TranslateNoColourIcon,
  SendIcon,
  AiUploadImgIcon,
  BlueSendIcon
} from "./icons";
import { KEYS } from "../keys";
import { debounce, delay, invert, reverse, throttle } from "lodash";
import { nanoid } from "nanoid";
import { AppState, StickyNote } from "../types";
import { t } from "../i18n";
import Spinner from "./Spinner";
import { DraggablePanel } from "./DraggablePanel";
import { IconSend, IconTrash, IconZoomOut } from "./newIcons";
import { StickyNoteBackground } from "./StickyNotePanel";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import { Popover } from "antd";
import { TranslateAIPopupChat } from "./TranslateAIPopup";
import { AiChatTranslateWin } from "./AiChatTranslateWin";
import { fileOpen, FileSystemHandle } from "../data/filesystem";
import { getDataURL, resizeImageFile } from "../data/blob";

export const requestAIChatbots = async ({ prompt, appState, imgUrl }: { prompt: string, appState: AppState, imgUrl?: string }) => {

  const response = await fetch(
    `/api/v1/ai/chatbots`,

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${appState.userInfo?.authorization}`,
      },
      body: JSON.stringify({
        model: imgUrl ? "llama3.2-vision" : "llama3.2",
        stream: false,
        images: imgUrl ? [imgUrl] : [],
        prompt
      })
    },
  );
  let anContent = (await response.json()).response;
  return anContent

}

export const AiChat: React.FC<{
  appState: AppState;
  setAppState?: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [messages, setMessages] = useState<
    (Message & { id: string; createdAt: Date, translateContent: string; msgType: string; images: string[]; })[]
  >([]);
  const [collabAPI] = useAtom(collabAPIAtom);
  const [loading, setLoading] = useState(false);
  const [requestAI, setRequestAI] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [translateText, setTranslatedText] = useState("");
  const [waitImgUrl, setWaitImgUrl] = useState("");
  const [isActive, setActive] = React.useState(false);
  // const [stickyNotes,setStickyNotes] = useState<any[]>([])
  const chatContentRef = React.useRef<HTMLDivElement>(null);
  const renderer = new marked.Renderer();
  const ollama = new Ollama({
    fetch: (url, init) =>
      fetch("/api/v1/ai/chatbots", {
        ...init,
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
        }),
      }),
  });

  // For code blocks with simple backticks
  renderer.codespan = (code) => {
    return `<code>${code.replaceAll("&amp;", "&")}</code>`;
  };
  useEffect(() => {
    chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
  });

  async function aiChat(): Promise<void> {
    if (!appState.userLicence?.aIText) {
      alert(t("alerts.shouldGrade"));
      return;
    }
    setRequestAI(true)
  }

  async function uploadImgToAIServer(url: string): Promise<void> {
    if (!appState.userLicence?.aIText) {
      alert(t("alerts.shouldGrade"));
      return;
    }


    if (url != '') {
      (async () => {
        const assistantMsgId = nanoid();
        let newMessages = [
          ...messages,
          {
            id: nanoid(),
            role: "user",
            content: url,
            translateContent: '',
            msgType: "image",
            images: [],
            createdAt: new Date()
          },
        ];
        setMessages(newMessages);
        const arr = url.split('base64,');
        setWaitImgUrl(arr[1])

      })();
    }

  }

  useEffect(() => {
    if (prompt != '') {
      (async () => {
        const assistantMsgId = nanoid();
        const sendMsg = {
          id: nanoid(),
          role: "user",
          content: prompt,
          translateContent: '',
          images: waitImgUrl !== "" ? [waitImgUrl] : [],
          msgType: "text",
          createdAt: new Date()
        }
        let newMessages = [
          ...messages,
          sendMsg,
          {
            id: assistantMsgId,
            role: "assistant",
            content: "",
            translateContent: '',
            images: [],
            msgType: "text",
            createdAt: new Date(),
          },
        ];
        setPrompt("");
        setWaitImgUrl("")
        setRequestAI(false)
        setMessages(newMessages);
        setLoading(true);


        ollama
          .generate({
            model: waitImgUrl !== "" ? "llama3.2-vision" : "llama3.2",
            prompt,
            stream: true,
            images: waitImgUrl !== "" ? [waitImgUrl] : []
          })
          .then(async (stream) => {
            setLoading(false);
            const content = "";
            let i = 0;
            for await (const chunk of stream) {
              delay(() => {
                newMessages = newMessages.map((m) => {
                  if (m.id == assistantMsgId) {
                    if (chunk.response != undefined) {
                      m.content += chunk.response;
                    }
                  }

                  return m;
                });
                setMessages(newMessages);
              }, 50 * i);
              // setMessages(newMessages);
              // delay(() => {
              //     content+=chunk.message.content;
              //     document.getElementById(assistantMsgId)!.innerHTML =  marked.parse(content) as string;
              //     chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
              // }, 50*i);
              i++;
            }

            // setMessages(newMessages);
          });

      })();
    }

  }, [requestAI]);

  const removeMessage = (id: string) => {
    setMessages(messages.filter((s) => s.id != id));
  };

  const handleOnClick = (message: Message) => {
    setActive(!isActive);
    //translateText
  };

  const UploadImageAction = async (
    { insertOnCanvasDirectly, callback } = {
      insertOnCanvasDirectly: false,
      callback: () => { },
    },
  ) => {
    try {

      const imageFile = await fileOpen({
        description: "Image",
        extensions: ["jpg", "png", "svg", "gif"],
      });

      const cursorImageSizePx = 320;
      const imagePreview = await resizeImageFile(imageFile, {
        maxWidthOrHeight: cursorImageSizePx,
      });

      let previewDataURL = await getDataURL(imagePreview);
      await uploadImgToAIServer(previewDataURL)

    } catch (error: any) {
      if (error.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }

      // this.setState(
      //   {
      //     pendingImageElementId: null,
      //     editingElement: null,
      //     activeTool: updateActiveTool(this.state, { type: "selection" }),
      //   },
      //   () => {
      //     this.actionManager.executeAction(actionFinalize);
      //   },
      // );
    }
  };

  return (
    <div className="ai-chat">
      <div className="body" ref={chatContentRef}>
        <List
          itemLayout="vertical"
          dataSource={messages}
          size="small"
          split={false}
          renderItem={(item, i) => (
            <List.Item key={i}>
              <>
                {/* <List.Item.Meta                    
                    avatar={ProfileIcon} 
                    title={item.role=="user"?"YOU":"IMAGOAI"}
                    description={new Date(item.createdAt).toLocaleString()}
                    /> */}
                {item.msgType !== "image" && (<div>
                  <b>{item.role == "user" ? "YOU" : "IMAGO AI"}</b>
                </div>)}

                {!item.content ? (
                  <div style={{ height: 40 }}>
                    <Spinner />
                  </div> //<div id={item.id} dangerouslySetInnerHTML={{__html:marked.parse(item.content,{gfm: true,breaks: true,renderer}) as string}}></div>
                ) : (
                  <div id={item.id}>
                    {/* {!item.content && <div id="" style={{minHeight:40}}><Spinner /></div>}
                            {item.role=="user"?item.content:""} */}

                    {item.msgType == "text" ? (<>
                      {marked.lexer(item.content).map((t) => {
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked.parse(t.raw, {
                                ...marked.getDefaults(),
                                gfm: true,
                                breaks: true,
                                renderer,
                              }) as string,
                            }}
                          ></div>
                        );
                      })}
                    </>) : (<>

                      <img src={item.content} />

                    </>)}

                  </div>
                )}
                {(messages[i].translateContent && messages[i].translateContent != '') && (<div id={`translate_${item.id}`} style={{ padding: "5px 0", fontStyle: "italic" }}>

                  {marked.lexer(messages[i].translateContent).map((t) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked.parse(t.raw, {
                            ...marked.getDefaults(),
                            gfm: true,
                            breaks: true,
                            renderer,
                          }) as string,
                        }}
                      ></div>
                    );
                  })}

                </div>)}

                {item.msgType !== "image" && (
                  <div className="item-actions">
                    <a
                      href={undefined}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(item.content)
                          .then(() => {
                            console.log("Text copied to clipboard");
                            message.info("Text copied to clipboard");
                          })
                          .catch((err) => {
                            console.error("Failed to copy text:", err);
                            message.error("Failed to copy text");
                          });
                      }}
                    >
                      {IconCopy}
                    </a>

                    {item.role !== "user" && (
                      <a
                        href={undefined}
                        onClick={() => {

                          let userContent = messages[i - 1].content
                          let AIContent = item.content;

                          if (window.AndroidInterface) {

                            if (messages[i - 1].translateContent && messages[i - 1].translateContent != '') {
                              userContent += "\n\n" + messages[i - 1].translateContent
                            }

                            if (item.translateContent && item.translateContent != '') {
                              AIContent += "\n\n" + item.translateContent
                            }

                            const message = JSON.stringify({
                              event: "stickyNotes",
                              user: { content: userContent },
                              assistant: { content: AIContent },
                            });
                            window.AndroidInterface.sendMessage(message);
                          } else if (setAppState) {
                            console.log([messages[i - 1], item]);
                            // setStickyNotes([...stickyNotes,{user:messages[i-1],assistant:item}]);
                            const randomBackground = Math.floor(
                              (Math.random() * 10) / 2,
                            );
                            let stickyContent = marked
                              .lexer(item.content)
                              .map((t) => {
                                return marked.parse(t.raw, {
                                  ...marked.getDefaults(),
                                  gfm: true,
                                  breaks: true,
                                  renderer,
                                }) as string;
                              })
                              .join("");

                            if (messages[i - 1].translateContent && messages[i - 1].translateContent != '') {
                              userContent += "<br>" + messages[i - 1].translateContent
                            }

                            if (item.translateContent && item.translateContent != '') {
                              stickyContent += "<br>" + marked
                                .lexer(item.translateContent)
                                .map((t) => {
                                  return marked.parse(t.raw, {
                                    ...marked.getDefaults(),
                                    gfm: true,
                                    breaks: true,
                                    renderer,
                                  }) as string;
                                })
                                .join("");
                            }

                            const stickyNote: StickyNote = {
                              id: nanoid(),
                              key: nanoid(),
                              status: "expand",
                              content: `<h4>YOU:</h4><p>${userContent}</p><h4>AI:</h4><p>${stickyContent}</p>`,
                              background:
                                StickyNoteBackground[randomBackground],
                              fontStyle: "normal",
                              textDecoration: "none",
                              fontWeight: "normal",
                              position: null,
                            }
                            collabAPI?.addStickyNote(stickyNote)
                          }
                        }}
                      >
                        {IconUnion}
                      </a>
                    )}

                    <a
                      href={undefined}
                      onClick={() => {
                        removeMessage(item.id);
                      }}
                    >
                      {IconClose}
                    </a>

                    <AiChatTranslateWin translateResult={(translatedText) => {
                      messages[i].translateContent = translatedText
                      handleOnClick(item)

                    }} appState={appState} setAppState={setAppState} textValue={messages[i].content} />

                    {(messages[i].translateContent && messages[i].translateContent != '') && item.role === "user" && (
                      <a
                        style={{ paddingTop: "3px" }}
                        href={undefined}
                        onClick={() => {
                          console.log(messages[i].translateContent)
                          setPrompt(messages[i].translateContent)
                          aiChat()
                        }}
                      >
                        {IconSend}
                      </a>
                    )}

                  </div>
                )}


              </>
            </List.Item>
          )}
        />
      </div>

      <div className="header">
        <div className="left-tools">
          <div onClick={() => {
            UploadImageAction()
          }} className="upload-image">
            {AiUploadImgIcon}
          </div>
        </div>
        <Input
          placeholder={t("winTabList.chat_with_ai")}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyDown={(e) => {
            e.key == KEYS.ENTER && aiChat();

          }}
        />

        <div className="right-send-msg" onClick={aiChat}>
          {BlueSendIcon}
        </div>

      </div>
    </div>
  );
};
