import React, { useEffect, useState } from "react"
import "./UserPlanChange.scss"
import { GetLoginedUser } from "../utils"
import { freeDrewHitPoint } from "../element/collision";

export const UserPlanChange: React.FC<{
    onClose: () => void
}> = ({ onClose }) => {
    const user = GetLoginedUser();
    const rolePeriodEnd = new Date((user?.rolePeriodEnd ?? 0) * 1000).toLocaleDateString()
    const [submited, setSubmited] = useState<boolean>(false);

    const planDetail = {
        "Guest": ["1 Free license", "Your free license is available over the long term.", "Can upgrade current license to Pro plan."],
        "Free": ["1 Free license", "Your free license is available over the long term.", "Can upgrade current license to Pro plan."],

        "Standard": !user?.isFreeTrial ? [`1 <span>Standard</span> license on Free Trail.`, `After your trial ends, you will be charged as order starting <span>${rolePeriodEnd}</span>.`, `You can always cancel before then.`]
            : [`1 <span>Standard</span> license`, `Your free license will be expired on <span>${rolePeriodEnd}</span>`, `This plan can be unsubscribed anytime before renew automatically.`],
        "Pro": !user?.isFreeTrial ? [`1 <span>Pro</span> license on Free Trail.`, `After your trial ends, you will be charged as order starting <span>${rolePeriodEnd}</span>.`, `You can always cancel before then.`]
            : [`1 <span>Pro</span> license`, `Your free license will be expired on <span>${rolePeriodEnd}</span>`, `This plan can be unsubscribed anytime before renew automatically.`],
    }

    const [orderDetails, setOrderDetails] = useState<any>()

    useEffect(() => {

        if (user?.roleEn != "Free") {
            fetch("/api/v1/user-plan-order-details", {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + user?.authorization
                }
            }).then(async (response) => {
                if (response.ok) {
                    const data = await response.json()
                    if (data.code == 200) {
                        setOrderDetails(JSON.parse(data.data));
                    }
                    return data;
                } else {
                    throw new Error('error');
                }
            })
        }

    }, [])

    const onCancel = () => {
        if (orderDetails && orderDetails.subscription && orderDetails.subscription.id) {
            const subid = orderDetails.subscription.id;
            fetch("/api/v1/stripe-subscription?subid=" + subid, {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + user?.authorization
                }
            }).then(async (response) => {
                if (response.ok) {
                    const data = await response.json()
                    if (data.code == 200) {
                        setSubmited(true);
                    } else {
                        alert("Operation failure");
                    }

                    return data;
                } else {
                    throw new Error('error');
                }
            })
        }
    }

    const onUpgrade = () => {
        location.href = "/pricing";
    }
    return <div className="user-plan-change">
        {
            !submited ? <>
                <h2>Plan</h2>
                <div className="plan-explain"><ul>{user?.roleEn !== "Guest" && planDetail[user?.roleEn ?? "Free"].map(t => {
                    return <li dangerouslySetInnerHTML={{ __html: t }}></li>
                })}</ul></div>
                {
                    (user?.roleEn == "Pro" || user?.roleEn == "Standard") && <>
                        <h2>Billing</h2>
                        <div>
                            <ul>
                                <li>Current order id is <span>**********{orderDetails?.id.slice(-6)}</span></li>
                                <li>Billing email is <span>{orderDetails?.customer_details.email}</span> </li>
                            </ul>
                        </div>
                    </>
                }
                <div className="user-plan-change-action">
                    {user?.roleEn == "Free" ? <button className="gree" onClick={() => { onUpgrade() }}>Upgrade</button> : user?.isFreeTrial ? <button className="cancel" onClick={onCancel}>Cancel Trail</button> : <button className="cancel" onClick={onCancel} >Unsubscribe</button>}
                </div>
            </>
                :
                <div className="user-plan-change-confirmed">
                    <h2>Your unsubscription is confirmed! </h2>
                    <p>
                        Thank you for using Collab and supporting Imago so much.<br />
                        We will improve our product and would love the opportunity to serve you in the future.
                    </p>
                    <p>
                        Current plan can be used 1 more day and be reactivated anytime.
                    </p>
                    <div className="user-plan-change-action">
                        <button className="gree" onClick={() => {
                            onClose();
                            location.href = "/dashboard?refresh_token=1"
                        }}>OK</button>
                    </div>
                </div>
        }

    </div>
}